import React from 'react';
import CaseDetailsCollapse from './CaseDetailsCollapse';
import { useTranslation } from 'react-i18next';

const CaseDetailsExtended = ({ caseData, changeset }) => {
  const { t } = useTranslation();

  const sections = [
    {
      key: 'social_and_family_context',
      title: t('case_details.social_and_family_context'),
      fields: [
        { key: 'social_history' },
        { key: 'family_history' },
        { key: 'risk_factors' },
      ],
    },
    {
      key: 'clinical_presentation',
      title: t('case_details.clinical_presentation'),
      fields: [
        { key: 'past_medical_history' },
        { key: 'past_surgical_history' },
        { key: 'current_illness_history' },
      ],
    },
    {
      key: 'medications_and_allergies',
      title: t('case_details.medications_and_allergies'),
      fields: [
        { key: 'medications' },
        { key: 'allergies' },
      ],
    },
    {
      key: 'clinical_evaluation',
      title: t('case_details.clinical_evaluation'),
      fields: [
        { key: 'review_of_systems' },
        { key: 'vital_signs_and_physical_exam' },
      ],
    },
    {
      key: 'diagnostic_evaluation',
      title: t('case_details.diagnostic_evaluation'),
      fields: [
        { key: 'laboratory_studies' },
        { key: 'imaging_studies' },
        { key: 'assessment' },
        { key: 'specialist_consultations' },
      ],
    },
    {
      key: 'treatment_and_follow_up',
      title: t('case_details.treatment_and_follow_up'),
      fields: [
        { key: 'treatment_plan' },
        { key: 'follow_up' },
      ],
    },
  ];

  return (
    <div className="case-details-extended">
      <CaseDetailsCollapse caseData={caseData} sections={sections} changeset={changeset} />
    </div>
  );
};

export default CaseDetailsExtended;
