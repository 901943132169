export default class CaseChangesPresenter {
  constructor(casePresenter, changeset) {
    this.casePresenter = casePresenter;
    this.changeset = this.formatKeys(changeset);
  }

  formatKeys(changeset) {
    // Remove 'gen_' prefix from keys: gen_blood_type => blood_type
    if (!changeset) return null;

    const formattedChangeset = {};

    Object.keys(changeset).forEach((key) => {
      const strippedKey = key.replace(/^gen_/, ''); // Remove 'gen_' prefix
      formattedChangeset[strippedKey] = changeset[key];
    });

    return formattedChangeset;
  }

  isUpdated(fieldName) {
    return this.changeset && this.changeset.hasOwnProperty(fieldName);
  }

  getFieldValue(fieldName) {
    // construct function name: blood_type => getBloodType
    const getterMethodName = `get${fieldName
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('')}`;
    return this.casePresenter[getterMethodName]();
  }
}
