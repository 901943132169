import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

const SessionDetailsLoadingFailedInfo = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Alert message={t('session_details_loading_failed_info.title')} type="error" />
        </div>
    );
};

export default SessionDetailsLoadingFailedInfo;
