import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, ConfigProvider, Tag, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import { getCurrentUser } from '../utils/userStorage';
import AccountDropdown from './AccountDropdown';
import ShareLoveButton from './buttons/ShareLoveButton';

const { Header } = Layout;
const { useBreakpoint } = Grid;

function NavigationHeader() {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const location = useLocation();
  const currentUser = getCurrentUser();
  const userEmail = currentUser.email;

  const getSelectedKey = () => {
    switch (location.pathname) {
      case '/':
        return '1';
      case '/sessions':
        return '2';
      default:
        return '';
    }
  };

  return (
    <ConfigProvider theme={{
      components: {
        Menu: {
          horizontalItemHoverColor: 'var(--main-color)',
          horizontalItemSelectedColor: 'var(--main-color)',
          itemSelectedColor: 'var(--main-color)',
        }
      }
    }}>
      <Header data-testid='navigation-header' style={{
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        padding: '0',
        paddingRight: '15px',
        backgroundColor: 'white'
      }}>
        <div style={{
          maxWidth: '720px',
          margin: '0 auto',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <Menu theme="light" mode="horizontal" selectedKeys={[getSelectedKey()]} items={[
            {
              label: <Link to="/">{t("navigation_header.record")}</Link>,
              key: '1',
            },
            {
              label: <Link to="/sessions">{t("navigation_header.sessions")}</Link>,
              key: '2',
            },
            {
              label: <Link to="/queries/new">{
                <>
                {t("navigation_header.queries")}
                { /* display New moniker only on large enough screens */ }
                { screens.sm ? (
                  <Tag color="red" style={{ marginLeft: '6px', fontSize: '12px', padding: '2px 4px', lineHeight: '1' }}>{t('common.new')}</Tag>
                ) : null }
                </>
              }</Link>,
              key: '3',
            },
          ]} style={{ flex: 1 }} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ShareLoveButton />
            <AccountDropdown userEmail={userEmail} />
          </div>
        </div>
      </Header>
    </ConfigProvider>
  );
}

export default NavigationHeader;
