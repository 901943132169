import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Modal, Button, Input } from 'antd';
import { BulbOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getDeviceInfo } from "../utils/getDeviceInfo";
import ReactMarkdown from 'react-markdown';
import authRequest from '../utils/authRequest';
import { logEvent } from '../analytics';
import { handleError } from "../utils/errorHandling";

const Demo = () => {
    const { TextArea } = Input;
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();

    const showModal = () => {
        logEvent('Demo', 'Open');
        setIsModalOpen(true);
    };

    function displayErrorModal(title, errMsg) {
        Modal.error({
            title: title,
            content: errMsg,
        });
        console.error(title, errMsg);
    }

    const handleGenerateNote = async () => {
        logEvent('Demo', 'GenerateNote');
        const deviceInfo = getDeviceInfo();
        const formData = new FormData();
        formData.append('device_info', JSON.stringify(deviceInfo));

        try {
            const response = await authRequest('/sessions/create_demo', {
                method: 'POST',
                body: formData
            });
            if (parseInt(response.status) !== 200) {
                displayErrorModal('Error', t('common.errors.general'));
            } else {
                const sessionUuid = response.data.uuid;
                console.log('Demo session created:', sessionUuid);
                navigate(`/sessions/${sessionUuid}?generating=1`);
                window.location.reload();
            }
            setIsModalOpen(false);
        } catch (error) {
            handleError(error);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Link to='#' onClick={showModal} data-testid='demo-link' style={{ textDecoration: 'underline', fontSize: 'medium' }}>
                <BulbOutlined className='iconSpacing' />
                <span>{t('demo.fields.try')}</span>
            </Link>
            <Modal
                title={t('demo.title')}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                width={600}
                data-testid='demo-modal'
            >
                <div
                    style={{
                        border: '1px solid #d9d9d9',
                        borderRadius: '2px',
                        padding: '4px 11px',
                        maxHeight: '300px',
                        overflow: 'auto',
                        backgroundColor: '#f5f5f5'
                    }}
                >
                    <ReactMarkdown>
                        {t('demo.fields.transcript')}
                    </ReactMarkdown>
                </div>
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                    <Button key="generateNote" type="primary" onClick={handleGenerateNote} data-testid='demo-generate-note'>
                        {t('demo.fields.generate_note')}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default Demo;