import React, { useState, useEffect } from 'react';
import { Collapse, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import CaseUpdatedTag from './CaseUpdatedTag';
import CaseChangesPresenter from '../../presenters/CaseChangesPresenter';

const { Panel } = Collapse;
const { Text } = Typography;

const CaseDetailsCollapse = ({ caseData, sections, changeset, defaultExpanded = false, hideEmptySubsection = false }) => {
  const { t } = useTranslation();
  const [activeKeys, setActiveKeys] = useState({});
  const changesPresenter = new CaseChangesPresenter(caseData, changeset);

  useEffect(() => {
    if (defaultExpanded) {
      const initialActiveKeys = sections.reduce((acc, section) => {
        acc[section.key] = section.key;
        return acc;
      }, {});
      setActiveKeys(initialActiveKeys);
    }
  }, [defaultExpanded, sections]);

  const handleCollapseChange = (sectionKey) => (keys) => {
    setActiveKeys(prevState => ({
      ...prevState,
      [sectionKey]: keys
    }));
  };

  const buildGetter = (key) => {
    return `get${key
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('')}`;
  };

  const getItemCount = (fields) => {
    return fields.filter((field) => {
      const value = caseData?.[buildGetter(field.key)]?.();
      return value && value !== 'N/A' && value.trim() !== '';
    }).length;
  };

  return (
    <>
      {sections.map((section, sectionIndex) => {
        const itemCount = getItemCount(section.fields);
        const isSectionUpdated = section.fields.some(field =>
          changesPresenter.isUpdated(field.key)
        );

        const title = (
          <span>
            {section.title} {isSectionUpdated && <CaseUpdatedTag />}
          </span>
        );

        return (
          <Collapse
            key={section.key}
            activeKey={activeKeys[section.key]}
            onChange={handleCollapseChange(section.key)}
            expandIconPosition="end"
            style={{
              background: 'white',
              border: '1px solid #f0f0f0',
              marginBottom: sectionIndex < sections.length - 1 ? '4px' : '0',
              opacity: itemCount === 0 ? 0.4 : 1
            }}
          >
            <Panel
              header={title}
              key={section.key}
              style={{
                background: 'white',
                border: 'none',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                overflow: 'hidden',
              }}
            >
              {section.fields.map((field) => {
                const value = caseData?.[buildGetter(field.key)]?.();
                if (hideEmptySubsection && (!value || value === 'N/A')) {
                  return null; // Skip rendering if hideEmptySubsection is true and value is "N/A"
                }
                return (
                  <React.Fragment key={field.key}>
                    <Text type="secondary">
                      {t(`case_details.fields.${field.key}`)}
                    </Text>
                    <ReactMarkdown style={{ display: 'block', marginBottom: '10px' }}>
                      {value && value !== 'N/A' ? value : 'N/A'}
                    </ReactMarkdown>
                  </React.Fragment>
                );
              })}
            </Panel>
          </Collapse>
        );
      })}
    </>
  );
};

export default CaseDetailsCollapse;
