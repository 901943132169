import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getToken } from '../utils/tokenStorage';

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const isAuthenticated = getToken();
    const searchParams = new URLSearchParams(location.search);
    const shouldRedirectToSignUp = searchParams.get('sign_up') === '1';

    return isAuthenticated ? (
      children
    ) : (
      <Navigate
        to={shouldRedirectToSignUp ? "/sign_up" : "/sign_in"}
        replace
        state={{ from: location }}
      />
    );
};

export default ProtectedRoute;
