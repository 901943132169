import React, { useState } from 'react';
import { Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import NoteTypeSelector from './NoteTypeSelector';
import { logEvent } from '../analytics';

const { Text } = Typography;

const RegenerateNote = ({ sessionUuid, disabled }) => {
  const { t } = useTranslation();
  const [showNoteTypeSelector, setShowNoteTypeSelector] = useState(false);

  const handleRegenerateClick = () => {
    if (disabled) return;
    logEvent('Note', 'Regenerate');
    setShowNoteTypeSelector(true);
  };

  return (
    <>
      <Text
        onClick={handleRegenerateClick}
        data-testid='regenerate-button'
        style={{ cursor: disabled ? 'not-allowed' : 'pointer', color: disabled ? 'gray' : 'inherit' }}
      >
        <ReloadOutlined /> {t('session_details.regenerate')}
      </Text>
      {showNoteTypeSelector && (
        <NoteTypeSelector
          sessionUuid={sessionUuid}
          detailsPage={true}
          visible={showNoteTypeSelector}
          onClose={() => setShowNoteTypeSelector(false)}
        />
      )}
    </>
  );
};

export default RegenerateNote;
