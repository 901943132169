import React from 'react';
import { Typography, Descriptions } from 'antd';

const { Title } = Typography;

const SessionDetailsHeader = ({ session, note, t }) => {
    return (
        <section className='session-details--header'>
            <Title level={3}>
                {session.getTitle(note)}
            </Title>
            <div className='session-details--descriptions'>
                <Descriptions column={1} title=''>
                    <Descriptions.Item label={t('session_details.fields.recorded')}>{session.getCompletedAt()}</Descriptions.Item>
                    <Descriptions.Item label={t('session_details.fields.duration')}>{session.getDuration()} {t('common.minutes')}</Descriptions.Item>
                </Descriptions>
            </div>
        </section>
    );
};

export default SessionDetailsHeader;
