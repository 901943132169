import { clearToken } from './tokenStorage';
import { clearCurrentUser } from './userStorage';
import { getErrorMessage } from "./errorHandling";
import { clearAudioDB } from './audioStorage';

const newSessionPath = '/sign_in'
let redirect = (url) => {
    window.location.href = url;
};

// needed for testing
export const setRedirect = (func) => {
    redirect = func;
};

export const handleAuthFailure = async (error = null) => {
    const baseURL = window.location.origin;
    const redirectUrl = new URL(baseURL + '/sign_in');
    const errorMessage = getErrorMessage(error);

    if (errorMessage) {
        redirectUrl.searchParams.set('error', errorMessage);
    }

    clearToken();
    clearCurrentUser();
    await clearAudioDB();

    // redirect to sign-in, unless the user is already on the sign-in page
    if (!window.location.pathname.includes(newSessionPath)) {
        redirect(redirectUrl.href);
    }
};
