import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

const SessionDetailsMedicalBasicInfo = ({ session }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Alert message={t('session_details_medical_basic_info.title')} type="info" />
        </div>
    );
};

export default SessionDetailsMedicalBasicInfo;
