import authRequest from './authRequest';
import NotePresenter from '../presenters/NotePresenter';

const fetchNote = async (noteUuid) => {
    try {
        const response = await authRequest(`/session_notes/${noteUuid}`, { method: 'GET' });
        if (parseInt(response.status) !== 200) {
            throw new Error('Fetching noted failed');
        }
        return new NotePresenter(response.data);
    } catch (error) {
        console.error('Fetching noted failed:', error);
        throw error;
    }
};

export default fetchNote;
