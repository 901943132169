import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

const SessionDetailsLoadingInfo = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Alert message={t('session_details_loading_info.title')} type="success" />
        </div>
    );
};

export default SessionDetailsLoadingInfo;
