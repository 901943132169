import React from 'react';
import { ThunderboltOutlined } from '@ant-design/icons';
import GradientButton from 'react-linear-gradient-button';
import { useTranslation } from 'react-i18next';

function FinalizeButton({ handleFinalize, hasStarted, hasPaused, isFinalizing }) {
    const { t } = useTranslation();
    const buttonText = t('audio_recorder.fields.finalize');
    const icon = <ThunderboltOutlined className='iconSpacing' />;

    return (
        <GradientButton
            gradiant="Rainbow Blue"
            theme="Rainbow Blue"
            color={'var(--main-color)'}
            onClick={handleFinalize}
            disabled={isFinalizing || !hasStarted}
            data-testid="finalize-button"
            style={{ visibility: hasPaused ? 'inherit' : 'hidden', minWidth: '250px' }}
        >
            {icon}{buttonText}
        </GradientButton>
    );
}

export default FinalizeButton;
