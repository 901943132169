import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import AudioRecorder from '../components/AudioRecorder';

function Recording() {
    const { Text} = Typography;
    const { t, i18n } = useTranslation();
    const usagePath = i18n.language.includes('es') ? '/usage_es' : '/usage_en';


    return (
    <div className="page">
      <main className="audio-recorder-page">
        <AudioRecorder />
      </main>
        <div className='fixed-text'>
            <Text type="secondary" style={{ marginBottom: '5px' }}>
                <LockOutlined className='iconSpacing' />
                { t('recording.fields.security_note') }
            </Text>
            <Link to={ usagePath } target='_blank'>
                { t('recording.fields.how_inform_patients') }
            </Link>
        </div>
    </div>
  );
}

export default Recording;
