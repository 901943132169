import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { AudioOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";


const NewRecordingButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    };

    return (
        <Button
            icon={<AudioOutlined />}
            type="primary"
            onClick={handleClick}
            >
            { t('session_details.fields.start_new_recording')}
        </Button>
    );
};

export default NewRecordingButton;
