class NotePresenter {
  constructor(note) {
    this.note = note;
  }

  getUUID() {
    return this.note?.uuid;
  }

  getBody() {
    return this.note?.user_body ? this.note?.user_body : this.note?.gen_body;
  }

  setUserBody(body) {
    this.note.user_body = body;
  }

  getRating() {
    return this.note?.rating;
  }

  isSoapNote() {
    return this.note && this.note.note_type_slug &&
      (this.note.note_type_slug === 'first_visit' || this.note.note_type_slug === 'follow_up_visit');
  }

  isDoctorNote() {
    return this.note && this.note.note_type_slug && this.note.note_type_slug !== 'patient_instructions';
  }

  isComplete() {
    return this.note ? this.note.completed && this.note.gen_body : false;
  }

  isValid() {
    return this.note && this.isComplete() && this.note.gen_body && !this.note.problem;
  }

  getTitle() {
    return this.note?.gen_title || this.note?.default_title || '';
  }

  canBeRegenerated() {
    return this.note && this.isComplete() && this.note.gen_body;
  }
}

export default NotePresenter;
