import React from 'react';
import { Typography, Tag } from 'antd';
import { BookTwoTone } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const { Title, Paragraph } = Typography;

const QueryHeader = () => {
  const { t } = useTranslation();

  return (
    <div className='queries--header'>
      <span>
        <Title level={3}>
          <BookTwoTone /> { t('query_details.title') }
          <Tag color="orange" style={{ marginLeft: '10px' }}>Beta</Tag>
        </Title>
        <Paragraph style={{ fontSize: '16px' }}>
          { t('query_details.subtitle') }
        </Paragraph>
      </span>
    </div>
  );
};

export default QueryHeader;
