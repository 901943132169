import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import QueryHeader from "../components/QueryHeader";
import RecentQueries from '../components/RecentQueries';
import QueryFormCard from '../components/QueryFormCard';
import fetchQueriesList from '../utils/fetchQueriesList';
import { logEvent } from '../analytics';

const QueryNew = () => {
  const [exampleQuery, setExampleQuery] = useState('');
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [hasRecentQueries, setHasRecentQueries] = useState(false);

  const handleReloadRecentQueries = () => {
    setReloadTrigger(prev => prev + 1);
  };

  const handleExampleQuery = (query) => {
    setExampleQuery(query);
    logEvent('QueryNew', 'ExampleQuestion');
  };

  useEffect(() => {
    const checkRecentQueries = async () => {
      const { queries } = await fetchQueriesList(1); // Fetch the first page of queries
      setHasRecentQueries(queries.length > 0); // Set to true if there are any queries
    };

    checkRecentQueries();
  }, [reloadTrigger]);

  return (
    <div className="queries-page">
      <QueryHeader />

      {hasRecentQueries ? (
        <Row gutter={[24, 24]}>
          {/* Recent Queries (Sidebar) */}
          <Col xs={24} md={8} lg={8}>
            <RecentQueries reloadTrigger={reloadTrigger} onNewQueryPage={true} />
          </Col>

          {/* Main Content - New Query Form */}
          <Col xs={24} md={16} lg={16}>
            <QueryFormCard
              initialQuestion={exampleQuery}
              onSubmit={handleReloadRecentQueries}
              handleExampleQuery={handleExampleQuery}
            />
          </Col>
        </Row>
      ) : (
        <QueryFormCard
          initialQuestion={exampleQuery}
          onSubmit={handleReloadRecentQueries}
          handleExampleQuery={handleExampleQuery}
        />
      )}
    </div>
  );
};

export default QueryNew;
