import React from 'react';
import ReactMarkdown from 'react-markdown';

// List of unwanted domains
const unwantedDomains = ['cnn.com', 'wikipedia.org', 'healthline.com', 'everydayhealth.com', 'yahoo.com', 'verywellhealth.com', 'bbc.com', 'medicalnewstoday.com', 'webmd.com'];

const removeUnwantedLinks = (text) => {
  const domainPattern = unwantedDomains.map(domain => `https?://[^\\s]*${domain}`).join('|');
  const regex = new RegExp(domainPattern, 'i');
  const lines = text.split('\n');
  const filteredLines = lines.filter(line => !regex.test(line));
  return filteredLines.join('\n');
};

const AnswerMarkdown = ({ children }) => {
  const processedText = removeUnwantedLinks(children);

  return (
    <ReactMarkdown
      components={{
        a: ({ node, ...props }) => (
          <a {...props} target="_blank" rel="noopener noreferrer">
            {props.children}
          </a>
        ),
      }}
    >
      {processedText}
    </ReactMarkdown>
  );
};

export default AnswerMarkdown;
