import React, { useMemo } from 'react';
import { Typography, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;

const CaseQuerySuggestedQuestions = React.memo(({ onQuestionClick }) => {
  const { t } = useTranslation();

  const sampledQuestions = useMemo(() => {
    const suggestedQuestions = t('case_queries.suggested_questions', { returnObjects: true });
    const shuffled = [...suggestedQuestions].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 4);
  }, [t]);

  return (
    <>
      <Text strong>{t('case_queries.example_questions')}</Text>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', paddingTop: '12px', paddingBottom: '24px' }}>
        {sampledQuestions.map((question, index) => (
          <Tag
            key={index}
            color="blue"
            onClick={() => onQuestionClick(question)}
            data-testid={`suggested-question-${index}`}
            style={{
              cursor: 'pointer',
              padding: '5px 10px',
              borderRadius: '15px',
              marginBottom: '8px',
              whiteSpace: 'normal',
              height: 'auto',
              maxWidth: '100%'
            }}
          >
            {question}
          </Tag>
        ))}
      </div>
    </>
  );
});

export default CaseQuerySuggestedQuestions;
