import authRequest from './authRequest';

const createRating = async (rateableUuid, rateableType, value) => {
  try {
    const response = await authRequest('/ratings', {
      method: 'POST',
      data: {
        rateable_uuid: rateableUuid,
        rateable_type: rateableType,
        value: value,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Creating rating failed:', error);
    throw error;
  }
};

export default createRating;
