import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Form, Typography } from 'antd';
import EmailInput from '../components/forms/EmailInput';
import FormSubmit from '../components/forms/FormSubmit';

const ResetPasswordRequest = () => {
    const { Title} = Typography;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleResetPassword = async (values) => {
        setIsSubmitting(true);
        try {
            const server_host = process.env.REACT_APP_SERVER_HOST;
            const response = await axios.post(`${server_host}/password`, {
                user: {
                    email: values.email,
                },
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            if (response.status === 200) {
                toast.success(t('reset_password_request.success'));
                // Redirect to the home page after displaying the success toast
                setTimeout(() => navigate('/'), 3000);
            } else {
                handleError();
            }
        } catch (error) {
            handleError();
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleError = (err_msg) => {
        toast.error(t('reset_password_request.error'));
        console.error('Reset password error');
    }

    return (
        <div className='public-page'>
            <div className='form-sizing'>
                <Form form={form} onFinish={handleResetPassword}>
                    <Title level={4}>{t('reset_password_request.title')}</Title>
                    <EmailInput/>
                    <FormSubmit label={t('reset_password_request.fields.send_instructions')} isSubmitting={isSubmitting}/>
                </Form>
            </div>
        </div>
    );
};

export default ResetPasswordRequest;
