import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from 'antd';

const SessionCard = ({ session }) => {
    const { t } = useTranslation();
    const { Text } = Typography;

    // Get the most recent note from the session
    const note = session.getLastDoctorNote();
    const localCompletedAt = session.getCompletedAt();

    const cardBody = () => {
        if (session.isNonMedical()) {
            return (<Text italic>{t('session_details_non_medical_info.title')}</Text>);
        } else if (!session.isReady()) {
            return (<Text italic>{t('session_details_loading_info.short')}</Text>);
        } else {
            return (<Text>{note.getBody().substring(0, 150).replace(/#/g, "").concat('...')}</Text>);
        }
    };

    if (note) {
        return (
            <li className="sessions--session" key={session.getUUID()}>
                <Link to={`/sessions/${session.getUUID()}`} style={{ textDecoration: 'none' }}>
                    <Card size='small' extra={
                        <Text type='secondary'>{localCompletedAt}</Text>
                    } title={session.getTitle(note)}>
                        {cardBody()}
                    </Card>
                </Link>
            </li>
        );
    } else {
        // If session doesn't have notes, we simply don't display it. The span is just for testing.
        return <span data-testid='no_notes_available'/>;
    }
};

export default SessionCard;
