import ReactGA from 'react-ga4';
import { getCurrentUser } from './utils/userStorage';
import { ENVIRONMENT } from './environmentVariables';

const isProduction = ENVIRONMENT === 'production';

export const initGA = () => {
    if (isProduction) {
        try {
            ReactGA.initialize('G-3W1L765NTY');
        } catch (error) {
            console.error('Failed to initialize Google Analytics', error);
        }
    }
};

const shouldLogAnalytics = () => {
    if (!isProduction) {
        return false;
    }

    const currentUser = getCurrentUser();
    // Return true if there's no user or the user is not internal
    return !currentUser || !currentUser.internal;
};

export const logPageView = () => {
    if (shouldLogAnalytics()) {
        try {
            ReactGA.send({
                hitType: 'pageview',
                page: window.location.pathname + window.location.search
            });
        } catch (error) {
            console.error('Failed to log page view', error);
        }
    }
};

export const logEvent = (category, action, value = null) => {
    if (!shouldLogAnalytics()) {
        return;
    }

    // Do nothing if category or action is missing
    if (!category || !action) {
        console.log('Category or action missing, not logging event');
        return;
    }

    const eventParams = {
        event_category: category,
        event_label: action
    };

    if (value !== null) {
        eventParams.value = value;
    }

    const currentUser = getCurrentUser();
    if (currentUser && currentUser.uuid) {
        eventParams.user_id = currentUser.uuid;
    }

    // Create the event name by concatenating category and action with underscores
    const eventName = `${category}_${action}`.toLowerCase().replace(/\s+/g, '_');

    try {
        ReactGA.event(eventName, eventParams);
    } catch (error) {
        console.error('Failed to log event', error);
    }
};
