import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;

const CaseNewBenefits = () => {
  const { t } = useTranslation();

  const markdownStyle = {
    display: 'block',
    paddingLeft: '1em', // Adjust this to match the width of the emoji
    textIndent: '-1em', // Matches padding to align text after the emoji
    margin: 0, // Ensure there's no extra margin affecting alignment
  };

  return (
    <Row gutter={[16, 16]} style={{ maxWidth: '1000px', margin: '0 auto' }}>
      <Col xs={24} sm={24} md={8}>
        <Paragraph>
          <ReactMarkdown components={{ p: ({node, ...props}) => <p style={markdownStyle} {...props} /> }}>
            {t('case_new.fields.bullet1')}
          </ReactMarkdown>
        </Paragraph>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <Paragraph>
          <ReactMarkdown components={{ p: ({node, ...props}) => <p style={markdownStyle} {...props} /> }}>
            {t('case_new.fields.bullet2')}
          </ReactMarkdown>
        </Paragraph>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <Paragraph>
          <ReactMarkdown components={{ p: ({node, ...props}) => <p style={markdownStyle} {...props} /> }}>
            {t('case_new.fields.bullet3')}
          </ReactMarkdown>
        </Paragraph>
      </Col>
    </Row>
  );
};

export default CaseNewBenefits;
