// src/utils/supportedTypes.js
const supportedAudioFormats = [
    'audio/webm',
    'audio/mp4',
    'audio/mpeg',
    'audio/x-m4a',
    'audio/ogg',
    'audio/x-wav',
    'video/mpeg',
    'audio/flac'
];

export default supportedAudioFormats;
