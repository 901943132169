import React, { useState } from 'react';
import { Tabs } from 'antd';
import NoteCard from '../components/NoteCard';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../analytics';

const SessionDetailsTabs = ({ session }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('soapNote');
    const activeDoctorNote = session.getLastDoctorNote();
    const activePatientInstructionsNote = session.getLastPatientInstructionsNote();

    const handleTabChange = (key) => {
        setActiveTab(key);
        if (key === 'patientInstructions') {
            logEvent('PatientInstructionsTab', 'Open');
        }
    };

    return (
        <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            type="card"
            animated={true}
            className="session-details--tabs"
            style={{ marginTop: '10px' }}
        >
            <Tabs.TabPane tab={t('session_details.fields.tab_soap_note')} key="soapNote">
                <div className='session-details--tab-content' data-testid='soap-tab'>
                    <NoteCard
                        note={activeDoctorNote}
                        session={session}
                    />
                </div>
            </Tabs.TabPane>
            {activePatientInstructionsNote && <Tabs.TabPane tab={t('session_details.fields.tab_patient_instructions_note')} key='patientInstructions'>
                <div className='session-details--tab-content' data-testid='patient-instructions-tab'>
                    <NoteCard
                        note={activePatientInstructionsNote}
                        session={session}
                    />
                </div>
            </Tabs.TabPane>}
        </Tabs>
    );
};

export default SessionDetailsTabs;
