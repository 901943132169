import React, { useEffect, useRef } from 'react';
import createNote from '../utils/createNote';
import authRequest from '../utils/authRequest';
import { handleError } from '../utils/errorHandling';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import deleteSession from '../utils/deleteSession';

const NoteTypeSelector = ({ sessionUuid, detailsPage = false, visible, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalRef = useRef(null);

  useEffect(() => {
    if (!visible) return;

    const handleNoteTypeClick = async (noteTypeId) => {
      try {
        const data = await createNote(sessionUuid, noteTypeId);
        console.log('Note created:', data);
        navigate(`/sessions/${sessionUuid}?generating=1`);
        window.location.reload();
        Modal.destroyAll();
        onClose();
      } catch (error) {
        displayErrorModal('Error', t('common.errors.general'));
      }
    };

    const displayErrorModal = (title, errMsg) => {
      Modal.error({
        title: title,
        content: errMsg,
      });
      console.error(title, errMsg);
    };

    const handleCancelClick = () => {
      Modal.confirm({
        title: t('note_type_selector.confirm_title'),
        content: t('note_type_selector.confirm_context'),
        okText: t('note_type_selector.confirm_ok_text'),
        okType: 'danger',
        cancelText: t('note_type_selector.confirm_no_text'),
        onOk: async () => {
          const success = await deleteSession(sessionUuid);
          if (success) {
            Modal.destroyAll();
            window.location.reload();
          }
        },
      });
    };

    const noteTypeButtons = (data) => (
      <Space direction="vertical" style={{ width: '100%', display: 'flex', paddingTop: '15px' }}>
        {data.map((type) => (
          <div key={type.id} style={{ width: '100%' }}>
            <Button block onClick={() => handleNoteTypeClick(type.slug)} data-testid={`note-type-button-${type.id}`}>{type.title}</Button>
          </div>
        ))}
        <div>
          {!detailsPage && (
            <Button
              block
              danger
              onClick={handleCancelClick}
              data-testid="note-type-button-cancel"
            >
              {t('note_type_selector.delete')}
            </Button>
          )}
        </div>
      </Space>
    );

    authRequest('/verticals/1/note_types')
      .then(response => {
        if (parseInt(response.status) === 200) {
          return response.data;
        } else {
          throw new Error('Error fetching note types');
        }
      })
      .then(data => {
        if (Array.isArray(data) && modalRef.current === null) {
          modalRef.current = Modal.info({
            title: t('note_type_selector.title'),
            content: noteTypeButtons(data),
            okButtonProps: { style: { display: 'none' } },
            closable: detailsPage,
            onCancel: () => {
              modalRef.current = null;
              Modal.destroyAll();
              onClose();
            },
            icon: null,
          });
        }
      })
      .catch(error => {
        handleError(t('common.errors.general'));
        onClose();
      });
  }, [t, navigate, sessionUuid, detailsPage, visible, onClose]);

  return (
    <div data-testid="note-type-selector" />
  );
};

export default NoteTypeSelector;
