import React, { useState } from 'react';
import { Modal, Button, Typography, Space } from 'antd';
import { HeartTwoTone, LinkOutlined, MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { logEvent } from '../../analytics';

const { Text, Paragraph } = Typography;

const ShareLoveButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const SHARE_URL = "https://app.itaca.pro/sign_up?utm_source=share_love";

  const showModal = () => {
    logEvent('ShareLove', 'Open');
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCopyLink = () => {
    logEvent('ShareLove', 'Copy');

    navigator.clipboard.writeText(SHARE_URL).then(() => {
      toast.success(t('share_love.fields.link_copied'));
    }).catch(() => {
      toast.error(t('share_love.fields.copy_failed'));
    });
  };

  const handleSendEmail = () => {
    logEvent('ShareLove', 'SendEmail');

    const subject = encodeURIComponent(t('share_love.fields.email_subject'));
    const bodyString = t('share_love.fields.email_body') + SHARE_URL;
    const body = encodeURIComponent(bodyString);
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;

    // Open the mailto link in a new tab
    window.open(mailtoLink, '_blank');
  };

  return (
    <>
      <Button
        type="text"
        icon={<HeartTwoTone twoToneColor="#eb2f96" />}
        onClick={showModal}
        data-testid='share-love-button'
        style={{
          display: 'flex',
          alignItems: 'center',
          color: 'inherit',
          fontSize: 'inherit',
        }}
      />
      <Modal title={t('share_love.title')} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} width={450}>
        <Paragraph style={{ marginLeft: '20px' }}>
          <Text>{t('share_love.body')}</Text>
        </Paragraph>
        <Space direction="vertical" style={{ width: '100%', alignItems: 'center' }}>
          <Button type="primary" icon={<LinkOutlined />} onClick={handleCopyLink} data-testid='copy-link-button' style={{ width: '300px' }}>
            Copy Link
          </Button>
          <Button icon={<MailOutlined />} onClick={handleSendEmail} data-testid='send-email-button' style={{ width: '300px' }}>
            Send Email
          </Button>
        </Space>
      </Modal>
    </>
  );
};

export default ShareLoveButton;
