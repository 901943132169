import React from 'react';
import CaseDetailsCollapse from './CaseDetailsCollapse';
import { useTranslation } from 'react-i18next';

const insightsStyle = {
  marginBottom: '8px',
};

const CaseDetailsInsights = ({ caseData, changeset }) => {
  const { t } = useTranslation();

  const sections = [
    {
      key: 'ai_insights',
      title: t('case_details.ai_findings'),
      fields: [
        { key: 'considerations' },
        { key: 'inconsistencies' },
      ],
    },
  ];

  return (
    <div style={insightsStyle}>
      <CaseDetailsCollapse caseData={caseData} changeset={changeset} sections={sections} defaultExpanded={true} hideEmptySubsection={true} />
    </div>
  );
};

export default CaseDetailsInsights;
