import axios from 'axios';
import { getRefreshToken, setToken } from './tokenStorage';

export const refreshAuthToken = async () => {
  // Retrieve the refresh token internally
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    return null;
  }

  const server_host = process.env.REACT_APP_SERVER_HOST;
  const url = `${server_host}/users/token/refresh`;

  try {
    const response = await axios.post(url, { refresh_token: refreshToken });
    if (response.status === 200) {
      const newToken = response.data.token;
      // Save the new JWT and keep the existing refresh token
      setToken(newToken, refreshToken);
      return newToken;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
