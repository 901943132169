import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../../analytics';

const GetStartedButton = () => {
  const { t } = useTranslation();

  const handleGetStartedClick = () => {
    logEvent('SharedQuery', 'GetStarted');
    window.open('/sign_up', '_blank', 'noopener,noreferrer');
  };

  return (
    <Button
      type="primary"
      shape="round"
      size="large"
      onClick={handleGetStartedClick}
    >
      {t('sign_up.fields.get_started')}
    </Button>
  );
};

export default GetStartedButton;
