import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import { logEvent } from '../../analytics';

function CopyToClipboardButton({ textToCopy, disabled }) {
    const { t } = useTranslation();

    const handleCopy = () => {
        logEvent('Note', 'Copy');
        toast.success(t('session_details.copy.success'));
    };

    return (
        <div>
            <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
                <Button icon={<CopyOutlined />} disabled={disabled} data-testid='copy-button' >{t('session_details.copy.label')}</Button>
            </CopyToClipboard>
        </div>
    );
}

export default CopyToClipboardButton;
