import React from 'react';
import { PauseOutlined, AudioFilled } from '@ant-design/icons';
import GradientButton from 'react-linear-gradient-button';
import { useTranslation } from 'react-i18next';

function RecordingButton({ isRecording, hasStarted, toggleRecording }) {
    const { t } = useTranslation();
    const icon = isRecording ? <PauseOutlined className='iconSpacing' /> : <AudioFilled className='iconSpacing' />;

    function getButtonText() {
        if (isRecording) {
            return t('audio_recorder.fields.pause');
        } else if (hasStarted) {
            return t('audio_recorder.fields.resume');
        } else {
            return t('audio_recorder.fields.start');
        }
    }

    const buttonText = getButtonText();

    return (
        <GradientButton
            background=''
            theme='Rainbow Blue'
            color={'white'}
            onClick={toggleRecording}
            data-testid='toggle-recording-button'
            style={{ minWidth: '250px' }}
        >
            {icon}{buttonText}
        </GradientButton>
    );
}

export default RecordingButton;
