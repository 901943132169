import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Space, Skeleton } from 'antd';
import BackLink from '../components/BackLink';
import SessionDetailsLoadingInfo from '../components/SessionDetailsLoadingInfo';
import SessionDetailsLoadingFailedInfo from '../components/SessionDetailsLoadingFailedInfo';
import SessionDetailsNonMedicalInfo from '../components/SessionDetailsNonMedicalInfo';
import SessionDetailsMedicalBasicInfo from '../components/SessionDetailsMedicalBasicInfo';
import SessionDetailsHeader from '../components/SessionDetailsHeader';
import fetchSession from '../utils/fetchSession';
import { handleError } from '../utils/errorHandling';
import SessionPresenter from '../presenters/SessionPresenter';
import NewRecordingButton from '../components/buttons/NewRecordingButton';
import SessionDetailsTabs from '../components/SessionDetailsTabs';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const pollSession = async (uuid) => {
  const startTime = Date.now();
  const timeout = 300000; // 5 minutes in milliseconds

  while (true) {
    if (Date.now() - startTime > timeout) {
      throw new Error('Polling timeout reached');
    }
    console.log(`pollSession: fetching session uuid ${uuid}`);
    const receivedSession = await fetchSession(uuid);

    if (receivedSession.isReady()) {
      console.log(`pollSession: received session has necessary data, stopping polling`);
      return receivedSession;
    }

    await sleep(2000);
  }
};

function SessionDetails() {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const location = useLocation();
  const [session, setSession] = useState(new SessionPresenter(null));
  const [sessionLoading, setSessionLoading] = useState(true);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const query = new URLSearchParams(location.search);
  const generating = query.get('generating') === '1';

  useEffect(() => {
    const fetchSessionDetails = async () => {
      setSessionLoading(true);
      let loadingToastId;
      try {
        // Fetch initial session data
        const initialSession = await fetchSession(uuid);
        setSession(initialSession);
        setSessionLoading(false);

        // If generating, show loading toast and start polling
        if (generating) {
          loadingToastId = toast.loading(t('session_details.fields.generating'));
          const updatedSession = await pollSession(uuid);
          setSession(updatedSession);

          toast.dismiss(loadingToastId);
        }
      } catch (error) {
        handleError(error);
        setLoadingFailed(true)
        if (generating && loadingToastId) {
          toast.dismiss(loadingToastId);
        }
      }
    };

    fetchSessionDetails();
  }, [uuid]);

  if (sessionLoading) {
    return (
        <div className='page session-details'>
          <div className='sessions--skeleton' data-testid='loading-skeleton'>
            <Skeleton />
          </div>
        </div>
    );
  }

  return (
      <div className='page session-details'>
        <nav className='app-nav'>
          <BackLink to='/sessions' />
        </nav>
        <Space direction='vertical' size={16}>
          <SessionDetailsHeader session={session} note={session.getLastDoctorNote()} t={t} />
          {!loadingFailed && !session.isReady() && <SessionDetailsLoadingInfo />}
          {loadingFailed && !session.isReady() && <SessionDetailsLoadingFailedInfo />}
          {session.isNonMedical() && <SessionDetailsNonMedicalInfo session={session} generating={generating} />}
          {session.displayNote() && session.isMedicalBasic() && <SessionDetailsMedicalBasicInfo session={session} />}
          {session.displayNote() && <SessionDetailsTabs session={session} />}
          {session.isReady() &&
              <div style={{ textAlign: 'center' }}>
                <NewRecordingButton />
              </div>
          }
        </Space>
      </div>
  );
}

export default SessionDetails;
