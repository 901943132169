import React from 'react';
import { Button, Typography, Grid } from 'antd';

const { useBreakpoint } = Grid;
const { Text } = Typography;

const ExampleQuestionButton = ({ icon, text, onClick, style }) => {
  const screens = useBreakpoint();

  return (
    <Button
      type="default"
      block
      size="large"
      onClick={onClick}
      className="example-question-button"
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column', // Stack icon on top of the text
        alignItems: 'center',
        justifyContent: 'center', // Center content vertically
        height: screens.xs ? '200px' : '120px', // Set a fixed height, which should be larger on small screens
        whiteSpace: 'normal',
        padding: '10px',
        textAlign: 'center' // Center text alignment
      }}
    >
      {icon}
      <div style={{ marginTop: '8px' }}>
        <Text type='secondary'>{text}</Text>
      </div>
    </Button>
  );
};

export default ExampleQuestionButton;
