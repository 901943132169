import { useState, useEffect, useRef, useCallback } from 'react';

const SILENCE_THRESHOLD = 2;
const SILENCE_TIMEOUT = 10000; // 10 seconds

const useDetectSilence = (analyser, isRecording, hasPaused) => {
  const [problemType, setProblemType] = useState(null);
  const [micMutedCount, setMicMutedCount] = useState(0);
  const [silenceCount, setSilenceCount] = useState(0);
  const intervalRef = useRef(null);
  const silenceTimerRef = useRef(null);
  const isFirstCheck = useRef(true);

  const clearMicrophoneError = useCallback(() => {
    setProblemType(null);
  }, []);

  const clearSilenceTimer = useCallback(() => {
    clearTimeout(silenceTimerRef.current);
    silenceTimerRef.current = null;
  }, []);

  const checkForSilence = useCallback(() => {
    if (analyser && isRecording && !hasPaused) {
      console.log('checkForSilence: analyzing...')
      const bufferLength = analyser.fftSize;
      const dataArray = new Uint8Array(bufferLength);
      analyser.getByteTimeDomainData(dataArray);

      const averageVolume = dataArray.reduce((sum, value) => sum + Math.abs(value - 128), 0) / bufferLength;
      console.log('checkForSilence: averageVolume is ', averageVolume);

      // we need to skip the first check, because the averageVolume is always 0 initially
      if (isFirstCheck.current) {
        isFirstCheck.current = false;
        return;
      }

      if (averageVolume === 0) {
        clearSilenceTimer();
        if (problemType !== 'mic_muted') {
          setProblemType('mic_muted');
          setMicMutedCount(prev => prev + 1);
        }
      } else {
        if (problemType === 'mic_muted') {
          clearMicrophoneError();
        }

        if (averageVolume > 0 && averageVolume < SILENCE_THRESHOLD) {
          if (!silenceTimerRef.current) {
            silenceTimerRef.current = setTimeout(() => {
              setProblemType('silence');
              setSilenceCount(prev => prev + 1);
            }, SILENCE_TIMEOUT);
          }
        } else {
          clearSilenceTimer();
          if (problemType === 'silence') {
            clearMicrophoneError();
          }
        }
      }
    }
  }, [analyser, isRecording, hasPaused, problemType, clearMicrophoneError, clearSilenceTimer]);

  useEffect(() => {
    if (isRecording) {
      // When recording starts, set up an interval to periodically check for silence
      console.log('useDetectSilence: setting up checkForSilence interval')
      intervalRef.current = setInterval(() => {
        checkForSilence();
      }, 1000);
    } else {
      console.log('useDetectSilence: clearing up checkForSilence interval')
      // When recording stops, reset the problem type state and clear the interval
      clearMicrophoneError();
      clearInterval(intervalRef.current);
    }

    // Cleanup function to clear the interval when the component unmounts or when isRecording changes
    return () => clearInterval(intervalRef.current);
  }, [isRecording, checkForSilence, clearMicrophoneError]);

  const problemStats = {
    mic_muted: micMutedCount,
    silence: silenceCount
  };

  return {
    problemType,
    problemStats
  };
};

export default useDetectSilence;
