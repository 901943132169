import authRequest from './authRequest';
import NotePresenter from '../presenters/NotePresenter';

const updateNote = async (noteUuid, fields) => {
    try {
        const sessionNote = Object.fromEntries(
            Object.entries(fields).filter(([key, value]) => value !== undefined)
        );

        const response = await authRequest(`/session_notes/${noteUuid}/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            data: { session_note: sessionNote },
        });

        if (response.status !== 200) {
            throw new Error('Error updating note');
        }

        return new NotePresenter(response.data);
    } catch (error) {
        console.error('Error updating note:', error);
        throw error;
    }
};

export default updateNote;
