import React from 'react';
import { Layout } from 'antd';
import logo_es from '../../assets/logo_es.png';
import logo_en from '../../assets/logo_en.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PublicLayout = ({ children }) => {
    const { Content, Header, Footer } = Layout;
    const { i18n } = useTranslation();
    const logo = i18n.language.includes('es') ? logo_es : logo_en

    return (
        <Layout className='layout'>
            <Header className='header'>
                <Link to="/">
                    <img src={logo} alt="Logo" style={{height: '50px', paddingTop: '10px'}}/>
                </Link>
            </Header>
            <Content style={{padding: '0 50px'}}>
                <div className="site-layout-content">{children}</div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
            </Footer>
        </Layout>
    );
}

export default PublicLayout;
