import { openDB } from 'idb';

const DB_NAME = 'audioRecorderDB';
const STORE_NAME = 'audioChunks';

export const initDB = async () => {
    const db = await openDB(DB_NAME, 1, {
        upgrade(db) {
            const store = db.createObjectStore(STORE_NAME, {
                keyPath: ['sessionUuid', 'chunkIndex']
            });
            store.createIndex('by_session', 'sessionUuid');
        },
    });
    return db;
};

// Function to construct chunk data for storage - saving the blob as ArrayBuffer
// (saving blobs directly to indexedDB doesn't work in Safari)
const constructChunkDataForStorage = async (chunkData) => {
    const buffer = await chunkData.blob.arrayBuffer();
    const type = chunkData.blob.type;
    return { sessionUuid: chunkData.sessionUuid, chunkIndex: chunkData.chunkIndex, buffer, type };
};

// Function to deconstruct chunk data from storage - building a blob from ArrayBuffer (for Safari support)
const deconstructChunkDataFromStorage = (chunk) => {
    const blob = new Blob([chunk.buffer], { type: chunk.type });
    return { sessionUuid: chunk.sessionUuid, chunkIndex: chunk.chunkIndex, blob };
};

export const saveAudioBlob = async (chunkData) => {
    if (!chunkData.sessionUuid || chunkData.chunkIndex === undefined) {
        throw new Error('Invalid key value for IndexedDB');
    }
    const storageData = await constructChunkDataForStorage(chunkData);
    const db = await initDB();
    await db.put(STORE_NAME, storageData);
};

export const getAllSessions = async () => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    const sessions = await store.getAllKeys();
    const uniqueSessions = [...new Set(sessions.map(key => key[0]))];
    return uniqueSessions;
};

export const getChunkDataBySession = async (sessionUuid) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    const index = store.index('by_session');
    const chunks = await index.getAll(sessionUuid);
    return chunks.map(deconstructChunkDataFromStorage);
};

export const deleteAudioBlob = async (sessionUuid, chunkIndex) => {
    const db = await initDB();
    await db.delete(STORE_NAME, [sessionUuid, chunkIndex]);
};

export const clearAudioDB = async () => {
    await indexedDB.deleteDatabase(DB_NAME);
};
