import authRequest from './authRequest';
import CaseTextEntryPresenter from '../presenters/CaseTextEntryPresenter';

const createCaseTextEntry = async (caseUuid, textEntryContent) => {
  try {
    const response = await authRequest(`/cases/${caseUuid}/text_entries`, {
      method: 'POST',
      data: { content: textEntryContent },
    });

    if (response.status === 200) {
      return new CaseTextEntryPresenter(response.data);
    } else {
      throw new Error('Failed to create case text entry');
    }
  } catch (error) {
    console.error('Error creating case text entry:', error);
    throw error;
  }
};

export default createCaseTextEntry;
