import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <Result
      status="404"
      title={t("not_found_page.title")}
      subTitle={t("not_found_page.subtitle")}
      extra={<Button type="primary"><Link to="/">{t("not_found_page.cta")}</Link></Button>}
    />
  );
}

export default NotFoundPage;
