import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { LikeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { logEvent } from '../../analytics';
import createRating from '../../utils/createRating';

const ThumbsUpButton = ({ rateableUuid, rateableType, onRatingSubmitted }) => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(false);

  const handleThumbsUp = async () => {
    try {
      await createRating(rateableUuid, rateableType, 1);
      setHidden(true);
      logEvent('QueryDetails', 'ThumbsUp');
      toast.success(t('ratings.success'));
      onRatingSubmitted();
    } catch (error) {
      toast.error(t('ratings.error'));
    }
  };

  if (hidden) return null;

  return (
    <Button
      icon={<LikeOutlined />}
      onClick={handleThumbsUp}
      style={{ marginRight: '10px' }}
      data-testid="thumbs-up-button"
    />
  );
};

export default ThumbsUpButton;
