// src/components/NewQuestionButton.js

import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from "@ant-design/icons";

const NewQuestionButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNewQuestion = () => {
    navigate('/queries/new');
  };

  return (
    <Button icon={<PlusOutlined/>} type="default" onClick={handleNewQuestion}>
      {t('recent_queries.fields.new_question')}
    </Button>
  );
};

export default NewQuestionButton;
