import React from 'react';
import { Tooltip, Tag } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const CaseUpdatedTag = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('case_details.updated')}>
      <Tag icon={<CheckOutlined />} color="purple" style={{ marginLeft: '8px' }} data-testid="case-updated-tag" />
    </Tooltip>
  );
};

export default CaseUpdatedTag;
