import React from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function EditButton({ handleEditButtonClick }) {
  const { t } = useTranslation();

  return (
    <Button
      onClick={handleEditButtonClick}
      data-testid="edit-button" 
    >
      <EditOutlined /> {t('session_details.edit')}
    </Button>
  );
}

export default EditButton;
