import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import authRequest from '../utils/authRequest';
import { getToken } from '../utils/tokenStorage';
import { handleAuthSuccess } from "../utils/authSuccess";
import { toast } from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Form, Row, Col, Typography, Divider } from 'antd';
import EmailInput from '../components/forms/EmailInput';
import PasswordInput from '../components/forms/PasswordInput';
import FormSubmit from '../components/forms/FormSubmit';
import MarketingInfo from '../components/MarketingInfo';
import GoogleSignIn from "../components/GoogleSignIn";

const SignUp = () => {
    const { Title, Text } = Typography;
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const currentTimezone = moment.tz.guess();
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Determine the redirect URL only once
    const redirectUrl = location.state?.from?.pathname || '/';

    useEffect(() => {
        const token = getToken();
        if (token) {
            navigate(redirectUrl, { replace: true });
        }
    }, [navigate, redirectUrl]);

    const handleSignUp = async (values) => {
        setIsSubmitting(true);
        try {
            const { email, password } = values;
            const response = await authRequest('/signup', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    user: {
                        email,
                        password,
                        locale: i18n.language,
                        timezone: currentTimezone
                    },
                }),
            });

            if (response.status === 200) {
                const authHeader = response.headers.get('Authorization');
                if (authHeader) {
                    const authToken = authHeader.split(' ')[1];
                    handleAuthSuccess(authToken, redirectUrl);
                    toast.success(t('sign_up.success'));
                } else {
                    handleError(t('sign_up.errors.invalid_token'));
                }
            } else {
                handleError(response?.data?.error);
            }
        } catch (error) {
            handleError(error?.response?.data?.error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleError = (err_msg) => {
        toast.error(err_msg);
        console.error('Sign up error: ' + err_msg);
    };

    // Function to open terms page based on locale
    const openTermsPage = () => {
        const termsUrl = i18n.language.includes('es') ? '/terms_es' : '/terms_en';
        window.open(termsUrl, '_blank');
    };

    const openPrivacyPage = () => {
        const privacyUrl = i18n.language.includes('es') ? '/privacy_es' : '/privacy_en';
        window.open(privacyUrl, '_blank');
    };

    return (
      <div className='sign-up-page'>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="sign-up-page--row" type="flex" style={{ width: '100%' }}>
              <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 1 }}>
                  <div className='form-sizing'>
                      <Form form={form} onFinish={handleSignUp}>
                          <Title level={4}>{t('sign_up.title')}</Title>
                          <Form.Item>
                              <GoogleSignIn origin={redirectUrl} /> {/* Pass redirectUrl as origin */}
                          </Form.Item>
                          <Divider plain>{t('sign_in.fields.or')}</Divider>
                          <EmailInput/>
                          <PasswordInput/>
                          <FormSubmit label={t('sign_up.fields.sign_up')} isSubmitting={isSubmitting}/>
                          <p>
                              {t('sign_up.fields.have_account')} <Link to="/sign_in"
                                                                       data-testid="sign-in-link">{t('sign_up.fields.sign_in_here')}</Link>
                          </p>
                          <Text type="secondary" className='sign-up-page--legal'>
                              {t('sign_up.fields.agree_terms')}
                              <a href='#' onClick={openTermsPage}
                                 data-testid='terms-link'>{t('sign_up.fields.terms_link')}</a>
                              {t('sign_up.fields.privacy_connector')}
                              <a href='#' onClick={openPrivacyPage}
                                 data-testid='privacy-link'>{t('sign_up.fields.privacy_link')}</a>
                          </Text>
                      </Form>
                  </div>
              </Col>
              <Col xs={{span: 24, order: 2}} md={{span: 12, order: 2}} className="marketing-column">
                  <MarketingInfo/>
              </Col>
          </Row>
      </div>
    );
};

export default SignUp;
