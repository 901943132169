import React, { useState } from 'react';
import { Modal } from 'antd';
import { AppleOutlined, AndroidOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getDeviceInfo } from "../utils/getDeviceInfo";
import Bugsnag from '@bugsnag/js';

const useWakeLock = () => {
    const { t } = useTranslation();
    const deviceInfo = getDeviceInfo();
    const [wakeLock, setWakeLock] = useState(null);

    const showWakeLockNotSupportedModal = () => {
        return new Promise((resolve) => {
            Modal.warning({
                title: t('wake_lock.fields.not_supported_title'),
                content: (
                    <div data-testid='wake-lock-warning-modal'>
                        <div style={{ marginBottom: '10px' }}>
                            {t('wake_lock.fields.not_supported_body')}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <AppleOutlined style={{ fontSize: '30px', marginRight: '10px' }} />
                            {t('wake_lock.fields.instructions_ios')}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AndroidOutlined style={{ fontSize: '30px', marginRight: '10px' }} />
                            {t('wake_lock.fields.instructions_android')}
                        </div>
                    </div>
                ),
                onOk: () => resolve(),
            });
        });
    };

    const showWakeLockRejectedModal = (message) => {
        return new Promise((resolve) => {
            Modal.warning({
                title: t('wake_lock.fields.rejected_title'),
                content: (
                    <div data-testid='wake-lock-warning-modal'>
                        <div style={{ marginBottom: '10px' }}>
                            {t('wake_lock.fields.rejected_body')}
                        </div>
                    </div>
                ),
                onOk: () => resolve(),
            });
        });
    };

    const startWakeLock = async () => {
        // We don't want to mess with WakeLock on desktop computers
        if (deviceInfo.deviceType === 'Desktop') {
            console.log('startWakeLock: not running on Desktop');
            return true;
        }

        if (!('wakeLock' in navigator)) {
            await showWakeLockNotSupportedModal();
            return false; // Indicate that wake lock is not supported
        }

        if (wakeLock) return true; // Wake lock already active

        try {
            const newWakeLock = await navigator.wakeLock.request('screen');
            setWakeLock(newWakeLock);
            console.log('startWakeLock: WakeLock is active!');

            newWakeLock.addEventListener('release', () => {
                console.log('Screen Wake Lock released:', newWakeLock.released);
                setWakeLock(null);
            });
            return true;
        } catch (err) {
            console.error(`startWakeLock error: ${err.name}, ${err.message}`);
            Bugsnag.notify(err);
            await showWakeLockRejectedModal();
            return false;
        }
    };

    const stopWakeLock = async () => {
        // We don't want to mess with WakeLock on desktop computers
        if (deviceInfo.deviceType === 'Desktop') {
            console.log('stopWakeLock: not running on Desktop');
            return true;
        }

        if (!wakeLock) return; // Only stop if there is an active wake lock

        try {
            await wakeLock.release();
            setWakeLock(null);
            console.log('Wake Lock released');
        } catch (err) {
            console.error(`stopWakeLock error: ${err.name}, ${err.message}`);
            Bugsnag.notify(err);
        }
    };

    return { startWakeLock, stopWakeLock, wakeLock };
};

export default useWakeLock;