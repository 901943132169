import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Typography, Row, Col, Grid, Space } from 'antd';
import fetchCase from '../utils/fetchCase';
import CaseTextEntryForm from '../components/CaseTextEntryForm';
import CaseQueries from '../components/case/CaseQueries';
import CaseSummaryCard from '../components/case/CaseSummaryCard';
import CaseLoadingCard from '../components/case/CaseLoadingCard';
import useCaseTextEntryPolling from '../hooks/useCaseTextEntryPolling';
import { handleError } from '../utils/errorHandling';
import CaseDetailsExtended from '../components/case/CaseDetailsExtended';
import CaseDetailsInsights from '../components/case/CaseDetailsInsights';
import BackLink from '../components/BackLink';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const CaseDetails = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [caseData, setCaseData] = useState(null);
  const [changeset, setChangeset] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const [trigger, setTrigger] = useState(0); // Trigger state to reload case details
  const [clearChangeset, setClearChangeset] = useState(false); // New state to control clearing changeset

  const processFetchedEntry = useCallback(async (entryUuid) => {
    const fetchedEntry = await pollCaseTextEntry(entryUuid);

    if (fetchedEntry?.getChangeset()) {
      setChangeset(fetchedEntry.getChangeset());
      setClearChangeset(false); // Ensure changeset is not cleared until used
    }

    setTrigger(prev => prev + 1); // Trigger re-fetching the case
    return fetchedEntry;
  }, []);

  const loadCaseDetails = useCallback(async () => {
    try {
      setLoading(true);
      const casePresenter = await fetchCase(uuid);
      setCaseData(casePresenter);

      const latestEntry = casePresenter.getLatestTextEntry();
      // First, check if we need to clear the changeset based on the clearChangeset flag
      if (clearChangeset) {
        setChangeset(null);
        setClearChangeset(false);
      }

      // if changeset is present, we need to clear it on the next reload
      if (changeset) {
        setClearChangeset(true);
      }

      if (!latestEntry || latestEntry.isCompleted()) {
        // If there is no latest entry or the latest entry is already completed, exit early.
        return;
      }

      // If there's an entry that is not completed, poll it
      await processFetchedEntry(latestEntry.uuid)

    } catch (error) {
      handleError(error);
      navigate('/cases');
    } finally {
      setLoading(false);
    }
  }, [uuid, changeset]);

  const { processingEntry, pollCaseTextEntry } = useCaseTextEntryPolling();

  useEffect(() => {
    loadCaseDetails();
  }, [loadCaseDetails, trigger]); // Re-fetch case details when the trigger changes

  const handleEntrySubmitted = async (entryUuid) => {
    await processFetchedEntry(entryUuid);
  };

  if (loading || processingEntry) {
    return <CaseLoadingCard isProcessingEntry={processingEntry} />;
  }

  return (
    <div className="page" style={{ maxWidth: '1000px', margin: '0 auto' }}>
      <Row align="middle" gutter={[16, 16]} style={{ marginBottom: '16px' }}>
        <Col flex="none">
          <BackLink to="/cases" label={t('case_details.cases')} />
        </Col>
        <Col flex="auto">
          <Title level={4} style={{ margin: 0 }}>
            {caseData.getTitle()}
          </Title>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
        <Col xs={24}>
          <CaseSummaryCard casePresenter={caseData} changeset={changeset} />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
        <Col xs={24} md={screens.md ? 12 : 24}>
          <CaseDetailsExtended caseData={caseData} changeset={changeset} />
        </Col>
        <Col xs={24} md={screens.md ? 12 : 24}>
          {caseData.displayInsights() && <CaseDetailsInsights caseData={caseData} changeset={changeset} />}
          <CaseTextEntryForm
            caseUuid={uuid}
            onEntrySubmitted={handleEntrySubmitted}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <CaseQueries
            caseUuid={uuid}
            caseQueries={caseData?.getQueries()}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '16px', marginLeft: '7px' }}>
        <BackLink to="/cases" label={t('case_details.cases')} />
      </Row>
    </div>
  );
};

export default CaseDetails;
