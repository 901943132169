import axios from 'axios';
import { getToken } from './tokenStorage';
import { handleAuthFailure } from './authFailure';
import { getErrorMessage } from "./errorHandling";
import { refreshAuthToken } from './tokenRefresh';

const sendRequest = async (path, options = {}, authToken = null) => {
  const token = authToken || getToken();
  const server_host = process.env.REACT_APP_SERVER_HOST;
  const normalizedPath = path.startsWith('/') ? path : `/${path}`;
  const url = `${server_host}${normalizedPath}`;

  // Prepare headers
  const headers = options.headers || {};
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  // Prepare the axios request config
  const axiosRequest = {
    ...options,
    url,
    headers,
    method: options.method || 'get',
  };

  // Handle 'body' option as 'data' for axios
  if (options.body) {
    // Assume body is a plain object that needs to be stringified if Content-Type is application/json
    // This mimics the Fetch API behavior
    if (headers['Content-Type'] === 'application/json' && typeof options.body === 'object') {
      axiosRequest.data = JSON.stringify(options.body);
    } else {
      axiosRequest.data = options.body;
    }
  }

  return axios(axiosRequest);
};

const authRequest = async (path, options = {}, authToken = null) => {
  try {
    return await sendRequest(path, options, authToken);
  } catch (error) {
    if (error.isAxiosError && error.response && error.response.status === 401) {
      // Attempt to refresh token
      const newToken = await refreshAuthToken();
      if (newToken) {
        return await sendRequest(path, options, newToken);
      }
      // If refresh fails or no refresh token, handle auth failure
      await handleAuthFailure(error);
      return Promise.reject({ error: getErrorMessage(error) });
    } else if (error.isAxiosError && error.message === "Network Error") {
      return Promise.reject({ error: "Couldn't load the page. Please check your Internet connection" });
    } else {
      throw error;
    }
  }
};

export default authRequest;
