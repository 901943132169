import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleAuthFailure } from '../utils/authFailure';
import { handleAuthSuccess } from "../utils/authSuccess";
import { useTranslation } from 'react-i18next';
import { Skeleton } from "antd";

function GoogleAuthCallback() {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        const refreshToken = query.get('refresh_token');
        const error = query.get('error');
        const origin = query.get('origin') || '/';

        if (token) {
            // without timer, the BlankLayout of the loading page appears dark grey
            // the timer gives the DOM time to repaint the page properly??
            setTimeout(() => {
                handleAuthSuccess(token, refreshToken, origin);
            }, 500);
        } else if (error) {
            handleAuthFailure(error);
        }
    }, [location, navigate]);

    return (
      <div className='page'>
          <Skeleton active />
      </div>
    );
}

export default GoogleAuthCallback;
