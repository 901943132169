import React, { useRef, useEffect } from 'react';
import { List } from 'antd';
import { useTranslation } from 'react-i18next';
import CaseQueryBubble from './CaseQueryBubble';

const CaseQueryFeed = React.memo(({ queryFeed, isExpanded }) => {
  const { t } = useTranslation();
  const queryContainerRef = useRef(null);

  const containerStyle = {
    maxHeight: isExpanded ? 'none' : '400px', // Adjust maxHeight based on expanded state
    overflowY: 'auto',
    padding: '12px',
  };

  const listItemStyle = {
    display: 'flex',
    padding: '10px 0',
    border: 'none',
  };

  const questionStyle = {
    justifyContent: 'flex-end',
  };

  const answerStyle = {
    justifyContent: 'flex-start',
  };

  const emptyTextStyle = {
    color: '#666',
  };

  useEffect(() => {
    if (queryContainerRef.current) {
      queryContainerRef.current.scrollTop = queryContainerRef.current.scrollHeight;
    }
  }, [queryFeed]); // Scroll to bottom whenever the queryFeed updates

  return (
    <div
      ref={queryContainerRef}
      style={containerStyle}
    >
      <List
        dataSource={queryFeed}
        renderItem={(item) => (
          <>
            <List.Item style={{ ...listItemStyle, ...questionStyle }}>
              <CaseQueryBubble isQuestion content={item.question} completed />
            </List.Item>
            <List.Item style={{ ...listItemStyle, ...answerStyle }}>
              <CaseQueryBubble content={item.answer} completed={item.completed} />
            </List.Item>
          </>
        )}
        locale={{ emptyText: <span style={emptyTextStyle}>{t('case_queries.ask_any_question')}</span> }}
      />
    </div>
  );
});

export default CaseQueryFeed;
