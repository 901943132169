import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { DislikeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { logEvent } from '../../analytics';
import createRating from '../../utils/createRating';

const ThumbsDownButton = ({ rateableUuid, rateableType, onRatingSubmitted }) => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(false);

  const handleThumbsDown = async () => {
    try {
      await createRating(rateableUuid, rateableType, -1);
      setHidden(true);
      logEvent('QueryDetails', 'ThumbsDown');
      toast.success(t('ratings.success'));
      onRatingSubmitted();
    } catch (error) {
      toast.error(t('ratings.error'));
    }
  };

  if (hidden) return null;

  return (
    <Button
      icon={<DislikeOutlined />}
      onClick={handleThumbsDown}
      style={{ marginRight: '20px' }}
      data-testid="thumbs-down-button"
    />
  );
};

export default ThumbsDownButton;
