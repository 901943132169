import React from 'react';
import { getToken } from '../../utils/tokenStorage';
import { useTranslation } from 'react-i18next';

const SignOutButton = () => {
    const { t } = useTranslation();

    return (
    getToken() && (
      <span data-testid="sign-out">
        {t('sign_out.title')}
      </span>
    )
  );
};

export default SignOutButton;
