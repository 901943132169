import React from 'react';
import { Card, Typography, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const cardStyle = {
  maxWidth: '1000px',
  margin: '80px auto 0',
};

const textContainerStyle = {
  textAlign: 'center',
  marginTop: '16px',
};

const secondaryTextStyle = {
  marginTop: '8px',
  display: 'block',
};

const CaseLoadingCard = ({ isProcessingEntry }) => {
  const { t } = useTranslation();

  return (
    <Card style={cardStyle}>
      <div style={textContainerStyle}>
        <Text>
          {isProcessingEntry 
            ? t('case_details.fields.updating_note') 
            : t('case_details.fields.loading_case')}
        </Text>
        <Text style={secondaryTextStyle} type="secondary">
          {t('case_details.fields.loading_message')}
        </Text>
      </div>
      <Skeleton active />
    </Card>
  );
};

export default CaseLoadingCard;
