import React, { useRef, useState } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../../analytics';
import toast from "react-hot-toast";
import generatePDF, { Margin } from 'react-to-pdf';

const DownloadNoteAsPDFButton = ({ note, noteContentRef }) => {
    const { t } = useTranslation();
    const [isDownloading, setIsDownloading] = useState(false);
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 10); // YYYY-MM-DD
    const formattedTime = now.toTimeString().slice(0, 5).replace(':', '-');
    const filename = `${note.isDoctorNote() ? "soap_note" : "patient_instructions"}_${formattedDate}_${formattedTime}.pdf`;
    const options = {
        filename: filename,
        page: {
            margin: Margin.MEDIUM,
        }
    };

    const handleDownloadPDF = async () => {
        logEvent('Note', 'PDF');
        // Disable button when download starts
        setIsDownloading(true);
        await generatePDF(noteContentRef, options)
        // Re-enable button once promise settles
        setIsDownloading(false);
        toast.success(t('session_details.download_pdf.success'));
    }

    return (
        <Button
            icon={<DownloadOutlined />}
            onClick={handleDownloadPDF}
            data-testid='download-pdf-button'
            disabled={isDownloading || !note.isValid()}
        >
            {t('session_details.download_pdf.label')}
        </Button>
    );
};

export default DownloadNoteAsPDFButton;
