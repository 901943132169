import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment-timezone';
import 'moment/locale/es';
import translationEN from "../src/translationEN.json";
import translationES from "../src/translationES.json";

// Initialize moment with the detected or fallback language
function configMoment(language) {
    // Set locale to the detected or fallback language
    moment.locale(language);
}

i18n
    // detect user language, learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next, for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        detection: {
            order: ['navigator', 'htmlTag', 'querystring', 'cookie', 'localStorage', 'path', 'subdomain'],
          },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: translationEN
            },
            es: {
                translation: translationES
            }
        }
    })
    .then(() => {
        // After i18next initialization, initialize moment with the detected or fallback language
        const language = i18n.language || window.navigator.language;
        configMoment(language);
    });

export default i18n;
