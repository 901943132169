import { setToken } from './tokenStorage';
import { setCurrentUser } from './userStorage';
import { jwtDecode } from 'jwt-decode';

let redirect = (url) => {
    window.location.href = url;
};

export const setRedirect = (func) => {
    redirect = func;
};

export const handleAuthSuccess = (token, refreshToken, redirectUrl = '/') => {
    // Store the JWT token and refresh token
    setToken(token, refreshToken);

    // Decode the JWT to extract user data
    const decoded = jwtDecode(token);
    // Save user data using setCurrentUser
    setCurrentUser({
        uuid: decoded.uuid,
        email: decoded.email,
        timezone: decoded.timezone,
        locale: decoded.locale,
        internal: decoded.internal
    });

    // Redirect to the specified URL
    redirect(redirectUrl);
};
