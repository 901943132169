import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import createQuery from '../utils/createQuery';
import { handleError } from '../utils/errorHandling';
import { Spin } from 'antd';

const QueryFromUrl = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const question = queryParams.get('q');

    if (question) {
      const submitQuery = async () => {
        try {
          const newQuery = await createQuery(question);
          navigate(`/queries/${newQuery.uuid}`);
        } catch (error) {
          handleError('Failed to create query. Please try again.');
        }
      };

      submitQuery();
    } else {
      handleError('No question provided in the query parameter.');
    }
  }, [location, navigate]);

  return <Spin tip="Creating your query..." />;
};

export default QueryFromUrl;
