import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Form, Typography } from 'antd';
import PasswordInput from '../components/forms/PasswordInput';
import PasswordConfirmationInput from '../components/forms/PasswordConfirmationInput';
import FormSubmit from '../components/forms/FormSubmit';

const ResetPasswordForm = () => {
    const { Title} = Typography;
    const [form] = Form.useForm();
    const [resetToken, setResetToken] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Extract the resetToken from the URL query parameters
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const resetToken = searchParams.get('reset_password_token');
        if (resetToken) {
            setResetToken(resetToken);
        }
    }, []);

    const handleSubmit = async (values) => {
        const { password, passwordConfirmation } = values;
        if (password !== passwordConfirmation) {
            toast.error(t('reset_password_form.errors.no_match'));
            return;
        }

        try {
            const server_host = process.env.REACT_APP_SERVER_HOST;
            const response = await axios.put(`${server_host}/password`, {
                user: {
                    reset_password_token: resetToken,
                    password: password,
                    password_confirmation: passwordConfirmation,
                },
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                toast.success(t('reset_password_form.success'));
                // Redirect to the home page after displaying the success toast
                setTimeout(() => navigate('/'), 1500);
            } else {
                handleError(response.data.error)
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                handleError(error.response.data.error);
            } else {
                handleError(t('reset_password_form.errors.general'));
            }
        }
    };

    const handleError = (err_msg) => {
        toast.error(err_msg);
        console.error('Reset password error: ' + err_msg);
    }

    return (
        <div className='public-page'>
            <div className='form-sizing'>
                <Form form={form} onFinish={handleSubmit}>
                    <Title level={4}>{t('reset_password_form.title')}</Title>
                    <PasswordInput label={t('reset_password_form.fields.new_password')}/>
                    <PasswordConfirmationInput/>
                    <FormSubmit label={t('reset_password_form.fields.reset_password')}/>
                </Form>
            </div>
        </div>
    );
};

export default ResetPasswordForm;
