import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import QueryForm from '../components/forms/QueryForm';
import ExampleQuestionButton from '../components/buttons/ExampleQuestionButton';

const { Title } = Typography;

const QueryFormCard = ({ initialQuestion, onSubmit, handleExampleQuery }) => {
  const { t } = useTranslation();

  return (
    <Card style={{ maxWidth: 700, margin: '0 auto' }}>
      <Title level={4} style={{ marginTop: '0', marginBottom: '15px' }}>{t('query_new.title')}</Title>
      <QueryForm initialQuestion={initialQuestion} onSubmit={onSubmit} />

      {/* Example Questions Buttons */}
      <Title level={5}>{t('query_new.fields.examples_title')}</Title>
      <Row gutter={[16, 16]} style={{ marginTop: '10px' }}>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ExampleQuestionButton
            text={t('query_new.fields.example_q1')}
            onClick={() => handleExampleQuery(t('query_new.fields.example_q1'))}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ExampleQuestionButton
            text={t('query_new.fields.example_q2')}
            onClick={() => handleExampleQuery(t('query_new.fields.example_q2'))}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ExampleQuestionButton
            text={t('query_new.fields.example_q3')}
            onClick={() => handleExampleQuery(t('query_new.fields.example_q3'))}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ExampleQuestionButton
            text={t('query_new.fields.example_q4')}
            onClick={() => handleExampleQuery(t('query_new.fields.example_q4'))}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default QueryFormCard;
