const CURRENT_USER = 'currentUser';

export const setCurrentUser = (userData) => {
    if (userData) {
        localStorage.setItem(CURRENT_USER, JSON.stringify(userData));
    }
};

export const getCurrentUser = () => {
    const data = localStorage.getItem(CURRENT_USER);
    // Parse the JSON string back to an object
    return data ? JSON.parse(data) : null;
};

export const clearCurrentUser = () => {
    localStorage.removeItem(CURRENT_USER);
};
