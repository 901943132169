import authRequest from '../utils/authRequest';
import { handleError } from './errorHandling';

const deleteSession = async (sessionUuid) => {
  try {
    const response = await authRequest(`/sessions/${sessionUuid}`, {
      method: 'DELETE',
    });

    if (parseInt(response.status) === 200) {
      return true;
    } else {
      handleError(response.data.error);
      return false;
    }
  } catch (error) {
    handleError(error);
    return false;
  }
};

export default deleteSession;
