import React from 'react';

function Settings() {
  return (
    <div className="page">
      Settings
    </div>
  );
}

export default Settings;
