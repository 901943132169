import authRequest from './authRequest';

const createQuery = async (question, previousQueryUuid = null) => {
  try {
    const data = { query: { question } };
    if (previousQueryUuid) {
      data.query.previous_query_uuid = previousQueryUuid;
    }

    const response = await authRequest('/queries', {
      method: 'POST',
      data,
    });
    return response.data;
  } catch (error) {
    console.error('Creating query failed:', error);
    throw error;
  }
};

export default createQuery;
