import { toast } from "react-hot-toast";

export const getErrorMessage = (error) => {
    let message = null;

    if (error instanceof String || typeof error === 'string') {
        message = error;
    } else if (error?.response?.data?.error) {
        message = error.response.data.error;
    } else if (error?.error) {
        message = error.error;
    }
    return message;
}

export const handleError = (error) => {
    let message = '';

    if (error instanceof String || typeof error === 'string') {
        message = error;
    } else if (error?.response?.data?.error) {
        message = error.response.data.error;
    } else if (error?.error) {
        message = error.error;
    }

    if (message) {
        console.error(message);
        toast.error(message);
    }
}

export const handleWarning = (message) => {
    if (message) {
        toast(message, {
            icon: '❗',
        });
        console.warn(message);
    }
}
