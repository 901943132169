import moment from 'moment-timezone';
import NotePresenter from './NotePresenter';

class SessionPresenter {
    constructor(session) {
        this.session = session;
        this.defaultTitle = 'Generating';
    }

    getUUID() {
        return this.session?.uuid;
    }

    getCompletedAt() {
        return this.session && this.session.completed_at
            ? moment.utc(this.session.completed_at).local().fromNow()
            : null;
    }

    getDuration() {
        return this.session && this.session.duration
            ? Math.ceil(this.session.duration / 60)
            : 1;
    }

    hasTranscriptEvaluation() {
        return !!this.session?.gen_transcript_eval;
    }

    isReady() {
        return (
            (this.hasTranscriptEvaluation() || this.isDemo()) &&
            (this.isNonMedical() || this.mostRecentNoteIsCompleted())
        );
    }

    isNonMedical() {
        return this.session?.gen_transcript_eval === 'non_medical';
    }

    isMedicalBasic() {
        return this.session?.gen_transcript_eval === 'medical_basic';
    }

    isDemo() {
        return this.session?.demo;
    }

    getDoctorNotes() {
        return this.session?.doctor_notes?.map(note => new NotePresenter(note)) || [];
    }

    getLastDoctorNote() {
        const doctorNotes = this.getDoctorNotes();
        return doctorNotes[doctorNotes.length - 1];
    }

    getPatientInstructionsNotes() {
        return this.session?.patient_instruction_notes?.map(note => new NotePresenter(note)) || [];
    }

    getLastPatientInstructionsNote() {
        const notes = this.getPatientInstructionsNotes();
        return notes[notes.length - 1];
    }

    mostRecentNoteIsCompleted() {
        const mostRecentNote = this.getLastDoctorNote();
        return mostRecentNote && mostRecentNote.isComplete();
    }

    displayNote() {
        return this.mostRecentNoteIsCompleted() && !this.isNonMedical();
    }

    getFormattedTranscript() {
        return this.session?.gen_formatted_transcript;
    }

    getTitle(note) {
      const baseTitle = note.getTitle() || this.defaultTitle;
      return this.isDemo() ? `(demo) ${baseTitle}` : baseTitle;
    }
}

export default SessionPresenter;
