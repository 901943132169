import React, { useState, useEffect } from 'react';
import {Table, Button, Grid, Skeleton, Space} from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import fetchCasesList from '../utils/fetchCasesList';
import CaseNew from '../components/CaseNew';
import CaseEmptyState from '../components/case/CaseEmptyState';

const { useBreakpoint } = Grid;

const Cases = () => {
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const screens = useBreakpoint();

  useEffect(() => {
    fetchCases(pagination.current);
  }, []);

  const fetchCases = async (page) => {
    setLoading(true);
    const { cases, pagination: paginationData } = await fetchCasesList(page);
    setCases(cases);
    setPagination(prev => ({
      ...prev,
      current: paginationData.current_page,
      total: paginationData.total_entries,
    }));
    setLoading(false);
  };

  const handleTableChange = (pagination) => {
    fetchCases(pagination.current);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: t('cases.fields.title'),
      dataIndex: 'gen_title',
      key: 'gen_title',
      render: (text, record) => <Link to={`/cases/${record.uuid}`}>{record.getTitle()}</Link>,
    },
    ...(screens.sm ? [
      {
        title: t('cases.fields.summary'),
        dataIndex: 'gen_summary',
        key: 'gen_summary',
        render: (text, record) => record.getTruncatedSummary(),
      },
      {
        title: t('cases.fields.last_updated'),
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (text, record) => record.getUpdatedAt(),
      }
    ] : []),
  ];

  if (loading) {
    return (
      <div className="cases-page">
        <Skeleton active />
        <Space />
        <Skeleton active />
        <Space />
        <Skeleton active />
      </div>
    );
  }

  return (
    <div className="cases-page">
      {cases.length > 0 ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '16px' }}>
            <Button type="primary" size="large" onClick={showModal} data-testid="new-case-button">
              {t('cases.new_case')}
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={cases}
            rowKey="uuid"
            pagination={pagination.total > pagination.pageSize ? pagination : false}
            loading={loading}
            onChange={handleTableChange}
          />
        </>
      ) : (
        <CaseEmptyState showModal={showModal} />
      )}
      <CaseNew isVisible={isModalVisible} onClose={handleModalClose} />
    </div>
  );
};

export default Cases;
