import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';

const BackLink = ({ to, label = null }) => {
    const { t } = useTranslation();
    const to_label = label || t('common.back');

    return (
        <Link to={to}>
            <LeftOutlined />
            <span>{to_label}</span>
        </Link>
    );
};

export default BackLink;
