import React from 'react';
import { Layout } from 'antd';

// can be used for loading screens
const BlankLayout = ({ children }) => {
    const { Content, Footer } = Layout;

    return (
        <Layout className='layout'>
            <Content style={{ padding: '0 50px' }}>
                <div className='site-layout-content'>{children}</div>
            </Content>
        </Layout>
    );
};

export default BlankLayout;
