import { useEffect, useCallback } from 'react';

export const usePreventRecordingAbandon = (isRecording) => {
    const handleBeforeUnload = useCallback((event) => {
        if (isRecording) {
            event.preventDefault();
            event.returnValue = '';
        }
    }, [isRecording]);

    const addPreventListener = useCallback(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, [handleBeforeUnload]);

    const removePreventListener = useCallback(() => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [handleBeforeUnload]);

    useEffect(() => {
        if (isRecording) {
            addPreventListener();
        } else {
            removePreventListener();
        }
        return () => {
            removePreventListener();
        };
    }, [isRecording, addPreventListener, removePreventListener]);

    return { addPreventListener, removePreventListener };
};
