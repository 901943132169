import React, { useState, useEffect } from 'react';
import { Layout, Modal, Button } from 'antd';
import { Link } from 'react-router-dom';
import ProtectedRoute from '../ProtectedRoute';
import NavigationHeader from '../NavigationHeader';
import { useTranslation } from 'react-i18next';
import logo_es from '../../assets/logo_es.png';
import logo_en from '../../assets/logo_en.png';

const { Content, Footer } = Layout;

const ProtectedLayout = ({ children }) => {
  const { t, i18n } = useTranslation();
  const logo = i18n.language.includes('es') ? logo_es : logo_en

  return (
    <ProtectedRoute>
      <Layout className='layout'>
        <NavigationHeader />
        <Content>
          <div className="site-layout-content">{children}</div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <Link to="/">
            <img src={logo} alt="Logo" style={{height: '30px'}}/>
          </Link>
        </Footer>
      </Layout>
    </ProtectedRoute>
  );
};

export default ProtectedLayout;
