import authRequest from './authRequest';

const fetchQuery = async (uuid) => {
  try {
    const response = await authRequest(`/queries/${uuid}`, { method: 'GET' });
    return response.data;
  } catch (error) {
    console.error('Fetching query failed:', error);
    throw error;
  }
};

export default fetchQuery;
