import React, { useState, useEffect } from 'react';
import { useRecorder } from '../hooks/useRecorder';
import { useTimer } from '../hooks/useTimer';
import { usePreventRecordingAbandon } from '../hooks/usePreventRecordingAbandon';
import { formatTime } from '../utils/formatTime';
import { AudioOutlined, CloseOutlined } from "@ant-design/icons";
import { ConfigProvider, Divider, Typography, Modal, Button } from "antd";
import { useTranslation } from 'react-i18next';
import { logEvent } from '../analytics';
import RecordingButton from './buttons/RecordingButton';
import FinalizeButton from './buttons/FinalizeButton';
import Demo from './Demo';
import NoteTypeSelector from './NoteTypeSelector';
import completeSession from '../utils/completeSession';
import deleteSession from '../utils/deleteSession';
import MicrophoneError from './MicrophoneError';

// _initialChunksCount should be used only in tests, to simulate the number of chunks that have been recorded
const AudioRecorder = ({ _initialChunksCount = 0 }) => {
  const supportedAudioFormats = require('../utils/supportedAudioFormats').default;
  const audioMimeType = supportedAudioFormats.filter(MediaRecorder.isTypeSupported)[0];
  const [showNoteTypeSelector, setShowNoteTypeSelector] = useState(false);
  const [isFinalizing, setIsFinalizing] = useState(false);
  const { t } = useTranslation();
  const { Text, Title } = Typography;

  const {
    isRecording,
    hasStarted,
    hasPaused,
    toggleRecording,
    endRecording,
    sessionUuid,
    totalChunks,
    problemType,
    problemStats
  } = useRecorder(audioMimeType, _initialChunksCount);

  const timer = useTimer(isRecording, hasStarted);
  const { addPreventListener, removePreventListener } = usePreventRecordingAbandon(isRecording);

  const handleCloseNoteTypeSelector = () => {
    setShowNoteTypeSelector(false);
  };

  const displayWarningRecTooShort = () => {
    displayWarningModal(
      t('audio_recorder.errors.too_short_title'),
      t('audio_recorder.errors.too_short_body'),
      'rec-too-short-warning-modal'
    );
  };

  const displayWarningCompleteFailed = () => {
    displayWarningModal(
      t('audio_recorder.errors.complete_title'),
      t('audio_recorder.errors.complete_body'),
      'complete-failed-warning-modal'
    );
  };

  const displayWarningModal = (title, body, elemId) => {
    Modal.warn({
      title: title,
      content: <div data-testid={elemId}>{body}</div>,
    });
    console.error(title, body);
  };

  const handleFinalize = async (event) => {
    setIsFinalizing(true);
    if (totalChunks < 1) {
      console.log("handleFinalize: session too short");
      event.preventDefault();
      displayWarningRecTooShort();
      setIsFinalizing(false);
      return;
    }

    const success = await completeSession(sessionUuid, totalChunks, problemStats);
    if (success) {
      console.log("handleFinalize: complete request sent successfully");
      await endRecording();
      setShowNoteTypeSelector(true);
    } else {
      console.log("handleFinalize: complete request failed");
      event.preventDefault();
      displayWarningCompleteFailed();
      setIsFinalizing(false);
    }
  };

  const handleDiscard = () => {
    logEvent('AudioRecorder', 'DiscardOpen');

    Modal.confirm({
      title: t('note_type_selector.confirm_title'),
      content: t('note_type_selector.confirm_context'),
      okText: t('note_type_selector.confirm_ok_text'),
      okType: 'danger',
      cancelText: t('note_type_selector.confirm_no_text'),
      onOk: async () => {
        // Temporarily remove the prevent listener to allow the user to close the recording modal
        removePreventListener();
        const success = await deleteSession(sessionUuid);
        logEvent('AudioRecorder', 'DiscardConfirm');
        if (success) {
          Modal.destroyAll();
          window.location.href = '/';
        } else {
          // Re-adding the listener if the deletion was unsuccessful
          addPreventListener();
        }
      },
    });
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            // display the discard [x] button as grey
            colorError: 'grey'
          },
        },
      }}
    >
      <div className='audio-recorder'>
        <Modal
          open={hasStarted}
          title={t('audio_recorder.recording')}
          footer={
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', paddingBottom: '5px' }}>
              <MicrophoneError type={problemType} />
              <RecordingButton isRecording={isRecording} hasStarted={hasStarted} toggleRecording={toggleRecording} />
              <FinalizeButton
                handleFinalize={handleFinalize}
                hasStarted={hasStarted}
                hasPaused={hasPaused}
                isFinalizing={isFinalizing}
              />
            </div>
          }
          closable={false}
          className='audio-recorder--modal'
        >
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Text type="secondary">
              <AudioOutlined className='iconSpacing' />
              {t('recording.fields.location_note')}
            </Text>
            <div className="audio-recorder--timer">{formatTime(timer)}</div>
          </div>
          <Button
            icon={<CloseOutlined />}
            shape="circle"
            danger
            onClick={handleDiscard}
            style={{ position: 'absolute', top: 10, right: 10 }}
          />
        </Modal>
        <Title level={4} style={{ color: 'var(--text-color)' }}>{t('home.title')}</Title>
        <Text>{t('home.subtitle')}</Text>
        {!isRecording &&
          <RecordingButton
            isRecording={isRecording}
            hasStarted={hasStarted}
            toggleRecording={toggleRecording}
          />
        }
        {showNoteTypeSelector && (
          <NoteTypeSelector
            sessionUuid={sessionUuid}
            visible={showNoteTypeSelector}
            onClose={handleCloseNoteTypeSelector}
          />
        )}
        <Divider plain>{t('sign_in.fields.or')}</Divider>
        <Demo />
      </div>
    </ConfigProvider>
  );
};

export default AudioRecorder;
