import React from 'react';
import {Form, Input} from 'antd';
import {MailOutlined} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const EmailInput = ({ to }) => {
    const { t } = useTranslation();

    return (
        <Form.Item
            name="email"
            rules={[
                { required: true, message: t('common.errors.email_required') },
                { type: 'email', message: t('common.errors.email_invalid') }
            ]}
            validateTrigger={['onBlur', 'onSubmit']} // Trigger validation on blur and form submission

        >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder={t('common.fields.email')} data-testid="email-input" />
        </Form.Item>
    );
};

export default EmailInput;
