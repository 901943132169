import React from 'react';
import { Button } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import '../GoogleSignIn.css';

let handleSignIn = (origin) => {
    const server_host = process.env.REACT_APP_SERVER_HOST;
    let googleRedirectUrl = `${server_host}/auth/google_oauth2`;
    if (origin && origin !== '/') {
        googleRedirectUrl += `?origin=${encodeURIComponent(origin)}`;
    }
    console.log('redirecting to ', googleRedirectUrl);
    window.location.href = googleRedirectUrl;
};

// used in tests
export const setHandleSignIn = (func) => {
    handleSignIn = func;
};

const GoogleSignIn = ({ origin = '/' }) => {
    const { t } = useTranslation();

    return (
        <Button onClick={() => handleSignIn(origin)} icon={<GoogleOutlined />} type="secondary" className="google-signin-btn" data-testid="google-signin-button">
            { t('sign_in.fields.continue_with_google') }
        </Button>
    );
};

export default GoogleSignIn;
