import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import UAParser from 'ua-parser-js';

export const getDeviceInfo = () => {
    const parser = new UAParser();
    const result = parser.getResult();

    let device = 'Unknown';
    if (isMobile) device = 'Mobile';
    if (isTablet) device = 'Tablet';
    if (isDesktop) device = 'Desktop';

    return {
        deviceType: device,
        userAgent: navigator.userAgent,
        browser: result.browser.name,
        browserVersion: result.browser.version,
        os: result.os.name,
        osVersion: result.os.version,
        deviceModel: result.device.model,
        deviceVendor: result.device.vendor,
        deviceTypeDetail: result.device.type,
    };
};
