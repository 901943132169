import React from 'react';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

let ErrorBoundary = ({ children }) => <>{children}</>;

// configure BugSnag to register errors only on production
if (process.env.NODE_ENV === 'production') {
    const bugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY;

    // Initialize Bugsnag
    Bugsnag.start({
        apiKey: bugsnagApiKey,
        plugins: [new BugsnagPluginReact(React)]
    });

    // Initialize Bugsnag Performance Monitoring
    BugsnagPerformance.start({ apiKey: bugsnagApiKey });

    // Create an ErrorBoundary component with Bugsnag
    ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}

export { Bugsnag, ErrorBoundary };
