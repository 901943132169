import authRequest from './authRequest';
import Bugsnag from "@bugsnag/js";

// 28 seconds timeout, a bit less than Heroku's default 30 seconds timeout threshold
const CLIENT_TIMEOUT = 28000;

const sendAudioData = async (chunkData, authToken = null) => {
    const formData = new FormData();
    formData.append('audio', chunkData.blob);
    formData.append('index', chunkData.chunkIndex);

    try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), CLIENT_TIMEOUT);

        const response = await authRequest(`/sessions/${chunkData.sessionUuid}/upload_partial`, {
            method: 'POST',
            body: formData,
            signal: controller.signal,
        }, authToken);

        clearTimeout(timeoutId);

        if (response.status === 200) {
            return true;
        } else {
            Bugsnag.notify(new Error(`Error uploading audio chunk: ${JSON.stringify(response)}`))
            console.error(`Error uploading audio chunk: ${response.statusText}`);
            return false;
        }
    } catch (err) {
        Bugsnag.notify(err);
        console.error(`Error uploading audio chunk: ${err}`);
        return false;
    }
};

export default sendAudioData;
