import React from 'react';
import {Form, Input} from 'antd';
import {LockOutlined} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const PasswordConfirmationInput = ({ to }) => {
    const { t } = useTranslation();

    return (
        <Form.Item
            name="passwordConfirmation"
            dependencies={['password']}
            hasFeedback
            rules={[
                {
                    required: true,
                    message: t('reset_password_form.errors.confirmation_required'),
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error(t('reset_password_form.errors.no_match')));
                    },
                }),
            ]}
        >
            <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder={t('reset_password_form.fields.confirm_new_password')}
                data-testid="password-confirmation-input"
            />
        </Form.Item>
    );
};

export default PasswordConfirmationInput;
