import React from 'react';
import { Typography, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { AudioOutlined } from "@ant-design/icons";
import ReviewTranscriptButton from '../components/buttons/ReviewTranscriptButton';

const SessionDetailsNonMedicalInfo = ({ session, generating }) => {
    const { t } = useTranslation();
    const { Text } = Typography;

    return (
        <div>
            <Alert message={t('session_details_non_medical_info.title')} type="warning" />
            { /* display the Mic advice only if the session was just completed and generating=1 query param is present */}
            { generating && <Alert showIcon icon={<AudioOutlined />} message={t('session_details_non_medical_info.mic_advice')} type="info" style={{ marginTop: '15px' }}/> }
            <div style={{textAlign: 'center', marginTop: '20px'}}>
                {<ReviewTranscriptButton session={session}/>}
            </div>
        </div>
    );
};

export default SessionDetailsNonMedicalInfo;
