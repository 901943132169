import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import ThumbsUpButton from '../components/buttons/ThumbsUpButton';
import ThumbsDownButton from '../components/buttons/ThumbsDownButton';

const { Text } = Typography;

const ThumbsRating = ({ rateableUuid, rateableType, hint = null }) => {
  const [buttonsHidden, setButtonsHidden] = useState(false);

  const handleRatingSubmitted = () => {
    setButtonsHidden(true);
  };

  useEffect(() => {
    // Unhide the buttons when the rateableUuid changes
    setButtonsHidden(false);
  }, [rateableUuid]);

  if (buttonsHidden) {
    return null; // Don't render anything if buttons are hidden
  }

  return (
    <div>
      <ThumbsUpButton
        rateableUuid={rateableUuid}
        rateableType={rateableType}
        onRatingSubmitted={handleRatingSubmitted}
      />
      <ThumbsDownButton
        rateableUuid={rateableUuid}
        rateableType={rateableType}
        onRatingSubmitted={handleRatingSubmitted}
      />
      {hint && (
        <div style={{ marginTop: '8px' }}>
          <Text type="secondary">{ hint }</Text>
        </div>
      )}
    </div>
  );
};

export default ThumbsRating;
