import authRequest from './authRequest';
import CaseQueryPresenter from '../presenters/CaseQueryPresenter';

const createCaseQuery = async (caseUuid, queryContent) => {
  try {
    const response = await authRequest(`/cases/${caseUuid}/queries`, {
      method: 'POST',
      data: { question: queryContent },
    });

    if (response.status === 200) {
      return new CaseQueryPresenter(response.data);
    } else {
      throw new Error('Failed to create case query');
    }
  } catch (error) {
    console.error('Error creating case query:', error);
    throw error;
  }
};

export default createCaseQuery;
