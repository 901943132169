import { useState, useEffect } from 'react';

export const useTimer = (isRecording, hasStarted) => {
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        let interval = null;
        if (isRecording) {
            interval = setInterval(() => {
                setTimer((prevTime) => prevTime + 1);
            }, 1000);
        } else if (!isRecording && hasStarted) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isRecording, hasStarted]);

    return timer;
};
