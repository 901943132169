import React, { useState, useEffect } from 'react';
import { Button, Modal, Typography, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import fetchSession from '../../utils/fetchSession';
import { handleError } from '../../utils/errorHandling';
import { logEvent } from '../../analytics';
import ReactMarkdown from 'react-markdown';
import { MonitorOutlined } from "@ant-design/icons";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const pollSession = async (uuid, shouldContinuePolling, t) => {
    while (shouldContinuePolling()) {
        console.error('ReviewTranscriptButton: polling session data...');
        const session = await fetchSession(uuid);

        if (session.getFormattedTranscript()) {
            return session;
        }
        await sleep(2000);
    }
    console.error('ReviewTranscriptButton: Polling stopped because the dialog was closed');
    return null;
};

const ReviewTranscriptButton = ({ session, currentNote }) => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [transcript, setTranscript] = useState(session.getFormattedTranscript());
    const [loading, setLoading] = useState(false);
    const { Paragraph } = Typography;

    const showModal = () => {
        logEvent('ReviewTranscript', 'Open');
        setIsModalVisible(true);
        if (!session.getFormattedTranscript()) {
            setLoading(true);
            setTranscript(null);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const renderTranscript = () => {
        return (
            <div>
                <div
                    style={{
                        border: '1px solid #d9d9d9',
                        borderRadius: '2px',
                        padding: '4px 11px',
                        maxHeight: '500px',
                        overflow: 'auto',
                        backgroundColor: '#f5f5f5',
                    }}
                >
                    <ReactMarkdown>{transcript}</ReactMarkdown>
                </div>
            </div>
        );
    };

    useEffect(() => {
        let shouldContinuePolling = true;

        if (isModalVisible && !transcript) {
            pollSession(session.getUUID(), () => shouldContinuePolling, t)
                .then((updatedSession) => {
                    if (updatedSession) {
                        setTranscript(updatedSession.getFormattedTranscript());
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    handleError(error);
                    setLoading(false);
                });
        }

        return () => {
            shouldContinuePolling = false;
        };
    }, [isModalVisible, session, transcript, t]);

    return (
        <div>
            <Button
                icon={<MonitorOutlined />}
                type="link"
                onClick={showModal}
                data-testid="review-transcript-button"
                style={{ color: 'var(--main-color)', padding: '0px' }}
            >
                {t('review_transcript.title')}
            </Button>
            <Modal
                title={t('review_transcript.title')}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                closable
                data-testid="review-transcript-modal"
            >
                {loading ? (
                    <div data-testid="review-transcript-loading">
                        <Skeleton active />
                    </div>
                ) : transcript ? (
                    renderTranscript()
                ) : (
                    <Paragraph data-testid='transcript-not-available'>
                        {t('review_transcript.fields.not_available')}
                    </Paragraph>
                )}
            </Modal>
        </div>
    );
};

export default ReviewTranscriptButton;
