import { getDeviceInfo } from './getDeviceInfo';

const supportedBrowser = () => {
    const deviceInfo = getDeviceInfo();

    // List of supported mobile browsers
    const supportedMobileBrowsers = ['Chrome', 'Firefox', 'Safari', 'Samsung', 'Edge', 'EdgA'];

    if (
        (deviceInfo.deviceType === 'Mobile' || deviceInfo.deviceType === 'Tablet') &&
        !supportedMobileBrowsers.some(browser => deviceInfo.browser.includes(browser))
    ) {
        return false;
    }
    // On desktop we intend to support all browsers
    return true;
};

export default supportedBrowser;
