import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Import your authRequest function
import authRequest from '../utils/authRequest';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const EmailConfirmation = () => {
    const [confirmationStatus, setConfirmationStatus] = useState('loading');
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const confirmEmail = async () => {
            const queryParams = new URLSearchParams(location.search);
            const token = queryParams.get('confirmation_token');
            const path = `/confirmation?confirmation_token=${encodeURIComponent(token)}`;

            if (!token) {
                toast.error(t('email_confirmation.errors.invalid_token'));
                setConfirmationStatus('error');
                // Redirect to the home page after displaying the error toast
                setTimeout(() => navigate('/'), 1500);
                return;
            }

            try {
                const response = await authRequest(path, {
                    method: 'GET', // Specify the HTTP method
                });

                if (response.status === 200) {
                    toast.success(t('email_confirmation.success'));
                    // TODO: instead of redirecting to sign-in page, sign-in the user automatically
                    setTimeout(() => navigate('/sign_in'), 1500); // Redirect after a success toast
                    setConfirmationStatus('success');
                } else {
                    console.log("ERROR!!")
                    toast.error(t('email_confirmation.errors.general'));
                    setConfirmationStatus('error');
                    setTimeout(() => navigate('/'), 1500); // Redirect after an error toast
                }
            } catch (error) {
                toast.error(t('email_confirmation.errors.general'));
                setConfirmationStatus('error');
                setTimeout(() => navigate('/'), 1500); // Redirect after an error toast
            }
        };

        confirmEmail();
    }, [t, location, navigate]);

    return (
        <div className="public-page">
            {confirmationStatus === 'loading' && <p>{t('email_confirmation.fields.loading')}</p>}
            {confirmationStatus === 'success' && <div><h1>{t('email_confirmation.success')}</h1><p>{t('common.redirecting_home')}</p></div>}
            {confirmationStatus === 'error' && <div><h1>{t('common.error')}</h1><p>{t('email_confirmation.errors.general')}</p></div>}
        </div>
    );
};

export default EmailConfirmation;
