import React, { useEffect } from 'react';
import { ErrorBoundary } from './bugsnag';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Toaster } from 'react-hot-toast';
import PublicLayout from './components/layouts/PublicLayout';
import ProtectedLayout from './components/layouts/ProtectedLayout';
import BlankLayout from "./components/layouts/BlankLayout";
import LegalDocument from "./components/LegalDocument";
import Recording from './pages/Recording';
import Settings from './pages/Settings';
import Sessions from './pages/Sessions';
import SessionDetails from './pages/SessionDetails';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import EmailConfirmation from './pages/EmailConfirmation';
import ResetPasswordRequest from './pages/ResetPasswordRequest';
import ResetPasswordForm from "./pages/ResetPasswordForm";
import NotFoundPage from './pages/NotFoundPage';
import GoogleAuthCallback from './pages/GoogleAuthCallback';
import QueryDetails from './pages/QueryDetails';
import QueryNew from './pages/QueryNew';
import SharedQuery from './pages/SharedQuery';
import QueryFromUrl from './components/QueryFromUrl';
import { initGA } from './analytics';
import PageViewHandler from './components/PageViewHandler';
import { useRetryUpload } from './hooks/useRetryUpload';
import { useNetworkStatus } from './hooks/useNetworkStatus';
import './i18n';
import './App.css';
import Cases from './pages/Cases';
import CaseDetails from './pages/CaseDetails';

function App() {
  // Initialize Google Analytics
  useEffect(() => {
    initGA();
  }, []);

  const isOnline = useNetworkStatus();
  // Start the retry upload worker, should wake up every 20 seconds
  useRetryUpload(isOnline, 20000);

  return (
      <ErrorBoundary>
        <div className='App' data-testid='app'>
          {/* Global Antd settings */}
          <ConfigProvider theme={{
            token: {
              Input: {
                colorPrimary: 'var(--main-color)',
                colorPrimaryActive: 'var(--main-color)',
                colorPrimaryHover: 'var(--hover-color)',
              },
              Button: {
                colorPrimary: 'var(--main-color)',
                colorPrimaryActive: 'var(--main-color)',
                colorPrimaryHover: 'var(--hover-color)',
              },
              Descriptions: {
                itemPaddingBottom: 0
              },
            },
            components: {
              Tabs: {
                itemSelectedColor: '#333333',
                itemHoverColor: 'var(--main-color)',
              },
            }
          }}>
            <BrowserRouter>
              <PageViewHandler/>
              <Routes>
                {/* Protected routes */}
                <Route path="/" element={<ProtectedLayout><Recording/></ProtectedLayout>}/>
                <Route path="/settings" element={<ProtectedLayout><Settings/></ProtectedLayout>}/>
                <Route path="/sessions" element={<ProtectedLayout><Sessions/></ProtectedLayout>}/>
                <Route path="/sessions/:uuid" element={<ProtectedLayout><SessionDetails/></ProtectedLayout>}/>
                <Route path="/queries/new" element={<ProtectedLayout><QueryNew/></ProtectedLayout>}/>
                <Route path="/queries/:uuid" element={<ProtectedLayout><QueryDetails/></ProtectedLayout>}/>
                <Route path="/queries" element={<ProtectedLayout><QueryFromUrl/></ProtectedLayout>}/>
                <Route path="/cases" element={<ProtectedLayout><Cases/></ProtectedLayout>}/>
                <Route path="/cases/:uuid" element={<ProtectedLayout><CaseDetails/></ProtectedLayout>}/>

                {/* Public routes */}
                <Route path="/sign_in" element={<PublicLayout><SignIn/></PublicLayout>}/>
                <Route path="/sign_up" element={<PublicLayout><SignUp/></PublicLayout>}/>
                <Route path="/confirmation" element={<PublicLayout><EmailConfirmation/></PublicLayout>}/>
                <Route path="/reset_password" element={<PublicLayout><ResetPasswordRequest/></PublicLayout>}/>
                <Route path="/password/edit" element={<PublicLayout><ResetPasswordForm/></PublicLayout>}/>
                <Route path="/auth/callback" element={<BlankLayout><GoogleAuthCallback/></BlankLayout>}/>
                <Route path="/q/share/:share_token" element={<PublicLayout><SharedQuery/></PublicLayout>}/>

                {/* Legal documents */}
                <Route path="/terms_en" element={<PublicLayout><LegalDocument fileName="terms_en.md"/></PublicLayout>}/>
                <Route path="/terms_es" element={<PublicLayout><LegalDocument fileName="terms_es.md"/></PublicLayout>}/>
                <Route path="/privacy_en"
                       element={<PublicLayout><LegalDocument fileName="privacy_en.md"/></PublicLayout>}/>
                <Route path="/privacy_es"
                       element={<PublicLayout><LegalDocument fileName="privacy_es.md"/></PublicLayout>}/>
                <Route path="/usage_en" element={<PublicLayout><LegalDocument fileName="usage_en.md"/></PublicLayout>}/>
                <Route path="/usage_es" element={<PublicLayout><LegalDocument fileName="usage_es.md"/></PublicLayout>}/>

                {/* 404 Not Found Page */}
                <Route path="*" element={<NotFoundPage/>}/>
              </Routes>

              <Toaster
                  position="top-center"
                  containerStyle={{
                    marginTop: '55px',
                  }}
              />
            </BrowserRouter>
          </ConfigProvider>
        </div>
      </ErrorBoundary>
  );
}

export default App;
