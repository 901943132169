import React from 'react';
import { Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const { Text } = Typography;

const textAreaStyle = {
  padding: '10px',
  fontSize: '16px',
  width: '100%',
  resize: 'vertical',
  minHeight: '80px',
  background: 'whitesmoke'
};

const hintTextStyle = {
  display: 'block',
  marginBottom: '20px',
  fontSize: '12px',
  marginTop: '8px',
  marginLeft: '3px',
  textAlign: 'right',
};

const CaseQueryInputArea = React.memo(({ query, setQuery, handleSubmit, isSubmitting }) => {
  const { t } = useTranslation();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div style={{ padding: '0px 8px' }}>
      <TextArea 
        rows={3} 
        value={query} 
        onChange={(e) => setQuery(e.target.value)} 
        placeholder={t('case_queries.input_placeholder')}
        style={textAreaStyle}
        onKeyDown={handleKeyDown}
        disabled={isSubmitting}
      />
      <Text type="secondary" style={hintTextStyle}>
        {t('case_queries.submit_hint')}
      </Text>
    </div>
  );
});

export default CaseQueryInputArea;
