import React from 'react';
import { Dropdown, Avatar, Grid } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import SignOutButton from './buttons/SignOutButton';
import { useTranslation } from 'react-i18next';
import authRequest from '../utils/authRequest';
import { handleAuthFailure } from "../utils/authFailure";

const { useBreakpoint } = Grid;

function AccountDropdown({ userEmail }) {
  const screens = useBreakpoint();
  const { t } = useTranslation();

  const handleSignOut = async () => {
    try {
      // Send DELETE /logout request
      await authRequest('/logout', {
        method: 'DELETE',
      });
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      // No matter what, clear the token and currentUser, and navigate to sign-in page
      await handleAuthFailure();
    }
  };

  // Menu items for the dropdown
  const menuItems = [
    {
      key: '0',
      label: userEmail,
      disabled: true,
    },
    {
      type: 'divider',
    },
    {
      key: '1',
      icon: <LogoutOutlined />,
      label: <SignOutButton />,
      onClick: handleSignOut,
    },
  ];

  return (
    <Dropdown menu={{ items: menuItems }} trigger={['click']}>
      <button
        className='ant-dropdown-link'
        data-testid='dropdown-menu'
        onClick={e => e.preventDefault()}
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          color: 'inherit',
          fontSize: 'inherit',
        }}>
        { /* Display "Account" only on large enough screens */ }
        { screens.sm ? (
          <span style={{ marginRight: 8 }}>{t('settings.account')}</span>
        ) : null }
        <Avatar icon={<UserOutlined />} />
      </button>
    </Dropdown>
  );
}

export default AccountDropdown;
