// src/utils/completeSession.js
import authRequest from './authRequest';

const completeSession = async (sessionUuid, totalChunks, problemStats) => {
  const formData = new FormData();
  formData.append('reported_partials_count', totalChunks);
  formData.append('problems', JSON.stringify(problemStats));

  try {
    const response = await authRequest(`/sessions/${sessionUuid}/complete`, {
      method: 'PATCH',
      body: formData,
    });
    return response.status === 200;
  } catch (err) {
    console.error('Completing session failed:', err);
    return false;
  }
};

export default completeSession;
