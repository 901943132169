import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Empty, Skeleton, Pagination, Space } from "antd";
import SessionCard from '../components/SessionCard';
import { useTranslation } from 'react-i18next';
import fetchSessionsList from '../utils/fetchSessionsList';

function Sessions() {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ total_entries: 0 });
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { sessions, pagination } = await fetchSessionsList(currentPage);
      if (sessions.length === 0 && pagination.total_entries === 0) {
        console.error(t('sessions.error'));
      }
      setSessions(sessions);
      setPagination(pagination);
      setLoading(false);
    };

    fetchData();
  }, [currentPage, t]);

  if (loading) {
    return (
      <div className="page sessions sessions--skeleton">
        <Skeleton active />
        <Space />
        <Skeleton active />
        <Space />
        <Skeleton active />
      </div>
    );
  }

  if (sessions.length > 0) {
    return (
      <div className="page">
        <ul className="sessions">
          {sessions.map((session) => (
            <SessionCard key={session.getUUID()} session={session} />
          ))}
        </ul>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Pagination
            current={pagination.current_page}
            onChange={setCurrentPage}
            total={pagination.total_entries}
            pageSize={10}
            showSizeChanger={false}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="page">
        <Empty
          imageStyle={{ height: 80, margin: 60 }}
          description={
            <div data-testid='no_sessions'>
              <p>{t('sessions.fields.empty')}</p>
              <p>
                <Link to={'/'}>
                  {t('sessions.fields.record')}
                </Link>
              </p>
            </div>
          }
        />
      </div>
    );
  }
}

export default Sessions;
