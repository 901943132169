import authRequest from './authRequest';
import CasePresenter from '../presenters/CasePresenter';

const createCase = async (textEntryContent) => {
  try {
    const response = await authRequest('/cases', {
      method: 'POST',
      data: { case: { text_entry_content: textEntryContent } },
    });

    if (response.status === 200) {
      return new CasePresenter(response.data);
    } else {
      throw new Error('Failed to create case');
    }
  } catch (error) {
    console.error('Error creating case:', error);
    throw error;
  }
};

export default createCase;
